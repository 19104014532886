<template>
    <div>

        <!-- 比赛数据列表 -->
        <el-row>
          <el-col >
            <el-form :inline="true" :model="formData">
              <el-form-item style="" >
                <div style="text-align: center;font-size: 32px;color: black;">
                  <!--赛事列表标题 {{$t('phoneLanguage.matchList')}}-->
                  <!-- <div style="font-size: 20px; font-weight: bold;"></div> -->
                  <!-- 赛事列表 -->
                   <span>{{$t('phoneLanguage.eventList')}}</span>  
                </div>


                <el-row :gutter="12">
                    <el-col :sm="12" :xs="12">
                        <!-- 赛事年份选择 -->
                      <el-select v-model="selectYear" clearable :placeholder="$t('phoneLanguage.selectYear')" @change="inputYearChangeHandle">
                        <el-option
                          v-for="item in getUniqueYearlyRaces(competitionList)"
                          :key="item.id"
                          :label="item.year"
                          :value="item.year">
                        </el-option>
                      </el-select>
                    </el-col>

                    <el-col :sm="12" :xs="12" >
                      <el-input clearable prefix-icon="el-icon-search" @input="inputChangeHandle" v-model="formData.name" :placeholder="$t('phoneLanguage.inputMatchSearchHint')"  ></el-input>
                    </el-col>
                </el-row>
       

  
               
               
              </el-form-item>
             
            </el-form>
          </el-col>
          <!-- <el-col :span="8" type="flex" :align="'end'" justify="center">
            <el-button type="primary" @click="clickCreateButton">{{$t('competition.createCompetition')}}</el-button>
          </el-col> -->
        </el-row>

        <!-- 卡片 :offset="index > 0 ? 2 : 0"-->
               <!-- 
      logo 
       xs	<768px 响应式栅格数或者栅格属性对象 手机
       sm	≥768px 响应式栅格数或者栅格属性对象 网页
      -->
        <el-row :gutter="10" class="container-row" :style="{ height: (myHeight * 0.8) + 'px' }" >
  <el-col :xs="24" :sm="12" v-for="(item, index) in showDataList" :key="index" >

    <el-card :body-style="{ padding: '10px'}" shadow="hover"  style="margin: 10px; background-color: #ffffff4d; ">
      <div style="display: flex;justify-content: space-between">
        <div style="padding: 14px;">
          <span style="font-size: 20px;color: black;">{{ item.pigeonRaceName }}</span>
          <div class="bottom clearfix">
            <time style="font-size: 14px; color: #0000008A;" class="time">{{convertDateToString(item.pigeonRaceDate)}}</time>
          </div>
        </div>
        <div >
              <img style="width: 150px;height:75px;padding: 10px;" src="/image/bsg.png" class="image">
  
        </div>
      </div>
      

      <div style="display: flex;justify-content: space-between">
         <!-- 查看 -->
          <div><el-button icon="el-icon-monitor"  type="primary" size="mini" @click="onlineBtn(item)" class="btn_class">{{$t('phoneLanguage.view')}}</el-button></div>
          <!-- 直播中 -->
          <div v-show="item.raceStatus == 1"><el-button  icon="el-icon-thumb" type="primary" size="mini" @click="onlineBtn(item)" class="btn_class">{{$t('phoneLanguage.live')}}...</el-button></div>
        </div>
    </el-card>
  </el-col>
    <!-- <el-empty description="描述文字"></el-empty> 暂无数据-->
         <el-empty v-if="showDataList.length < 1" :description="$t('phoneLanguage.nodata')"></el-empty>
</el-row>


        <!-- TODO 先隐藏 数据表格 -->
        <el-table v-if="false"
             height="419"
        ref="filterTable" :data="showDataList"  :border="true" class="child-table"
         :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }"
        >
          <!-- <el-table-column fixed prop="index" :label="$t('common.sn')" width="80"></el-table-column> -->
          <!-- 比赛名称-->
          <el-table-column  prop="pigeonRaceName" :label="$t('phoneLanguage.matchName')" ></el-table-column>
          <!-- 比赛日期-->
          <el-table-column  :label="$t('phoneLanguage.matchDate')">
            <template slot-scope="scope">{{convertDateToString(scope.row.pigeonRaceDate)}}</template>
          </el-table-column>

          <!-- <el-table-column :label="$t('competition.createTime')" width="120">
            <template slot-scope="scope">{{convertDateToString(scope.row.createDatetime)}}</template>
          </el-table-column>
          <el-table-column :label="$t('competition.expiryDate')" width="120">
            <template slot-scope="scope">{{convertDateToString(scope.row.raceExpiryDate)}}</template>
          </el-table-column>
          <el-table-column :label="$t('competition.startLon')" width="120">
            <template slot-scope="scope">{{scope.row.starLon ? scope.row.starLon : ''}}</template>
          </el-table-column>
          <el-table-column :label="$t('competition.startLat')" width="120">
            <template slot-scope="scope">{{scope.row.starLat ? scope.row.starLat : ''}}</template>
          </el-table-column>
          <el-table-column :label="$t('competition.endLon')" width="120">
            <template slot-scope="scope">{{scope.row.endLon ? scope.row.endLon : ''}}</template>
          </el-table-column>
          <el-table-column :label="$t('competition.endLat')" width="120">
            <template slot-scope="scope">{{scope.row.endLat ? scope.row.endLat : ''}}</template>
          </el-table-column>
          <el-table-column prop="communityName" :label="$t('competition.pigeonGuildName')" width="120"></el-table-column> -->
        <!-- 操作-->
         <el-table-column  :label="$t('phoneLanguage.operation')">
            <template slot-scope="scope">
              <!-- 查看 -->
              <el-button type="success" size="mini" @click="onlineBtn(scope.row)" class="btn_class">{{$t('phoneLanguage.view')}}</el-button>
              <!-- <el-button type="success" size="mini" @click="onlineBtn(scope.row)" class="btn_class">直播模式</el-button>
              <el-button type="success" size="mini" @click="clickTrackBtn(scope.row)" class="btn_class" >{{$t('devicePage.trackPlayback')}}</el-button>
              <el-button type="warning" size="mini" @click="clickManageButton(scope.row)" class="btn_class" >{{$t('competition.manageDevice')}}</el-button>
              <el-button type="info" size="mini" @click="clickConfiguraButton(scope.row)" class="btn_class">{{$t('devicePage.configuration')}}</el-button>
              <el-button type="danger" size="mini" v-if="pwdType === '2'" @click="clickDeleteButton(scope.row)" class="btn_class">{{$t('common.delete')}}</el-button> -->
             
            </template>
          </el-table-column> 
        </el-table>

      <!-- TODO 分页 -->
      <div class="pagination" style="position: absolute; bottom: 20px;">
        <el-pagination
          background
          :small="true"
           :pager-count="5"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pagination.pageSize"
           layout="total, sizes, prev, pager, next"
          :total="pagination.totalData">
        </el-pagination>
      </div>
      <el-dialog :title="$t('competition.createCompetition')" :visible.sync="dialogFormVisible">
        <el-form ref="form" :model="competitionForm" label-width="120px">
          <el-form-item :label="$t('competition.name')">
            <el-input v-model="competitionForm.name" :placeholder="$t('competition.msg.inputNamePrompt')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('competition.date')">
            <el-date-picker v-model="competitionForm.dateValue" type="date" :placeholder="$t('competition.msg.selectDate')"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('pigeonGuild.pigeonGuildName')">
            <el-select v-model="competitionForm.guildId" :placeholder="$t('competition.selectGuild')">
              <el-option v-for="guildItem in guildList" :label="guildItem.userName" :value="guildItem.userId" :key="guildItem.userId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clickDialogConfirmButton">{{$t("common.confirm")}}</el-button>
            <el-button @click="dialogFormVisible = false">{{$t("common.cancel")}}</el-button>
          </el-form-item>
        </el-form>
        <!-- <p>{{$t('competition.msg.inputNamePrompt')}}<p>
        <el-input type="text" v-model="dialogInputValue" :placeholder="$t('competition.msg.inputNamePrompt')"></el-input>
        <p>{{$t('competition.msg.selectDate')}}</p>
        <el-date-picker
          v-model="dateValue"
          type="date"
          :placeholder="$t('competition.msg.selectDate')">
        </el-date-picker>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{$t('common.cancel')}}</el-button>
          <el-button type="primary" @click="clickDialogConfirmButton">{{$t('common.confirm')}}</el-button>
        </div> -->
      </el-dialog>
      <el-dialog :visible.sync="showConfigDialog" :title="($t('devicePage.configuration') + '(' + currentRaceName + ')')">
          <el-form :data="configurationData">
          </el-form>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.standbyDatetime')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('standbyDatetime')}}</el-col>
            <el-col :span="6">{{$t('config.cst')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('cst')}}</el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rsut')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="datetime-local" name="rsut" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('rsut')"/>
            </el-col>
            <el-col :span="6">{{$t('config.rgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.rgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.rdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.lpl')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpl"/>
              <span>&#37;</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.lpgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.lpdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.nmst')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="time" name="nmst" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('nmst')"/>
            </el-col>
            <el-col :span="6">{{$t('config.nmet')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="time" name="nmet" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('nmet')"/>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.ngli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.ngli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.ndui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.ndui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.cgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.cgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.cdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.cdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rdut')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="date" name="rdut" @input="timeInputHandler" :value="getConfValueByKey('rdut')" style="width: 95%"/>
            </el-col>
            <el-col :span="6">{{$t('config.gddt')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('gddt')}}</el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rsud')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" @input="inputDelayTime" name="rsud" :value="getConfValueByKey('rsud')">
              <span>{{this.$t('unit.minute')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="end">
              <input type="button" @click="clickConfirmUpdateConfigurationButton(configurationData)" :value="$t('common.confirm')">
          </el-row>
        </el-dialog>
    </div>
  </template>
  
  <script>
  import { createCompetition, deleteCompetition, queryCompetitionList, updateCompetitionDevice } from '@/api/competition'
  import {
    getDeviceConfiguration,
    updateDeviceConfiguration
  } from '@/api/gpsDevice'
  import { queryRaceList, queryRolesList, queryRaceConfig, updateRaceConfig} from '@/api/requestUtils'
  import moment from 'moment'
  import 'moment-timezone';
  import axios from 'axios'
  // Vue.prototype.$axios= axios
  export default {
    data () {
      return {
        selectYear:'', // 塞选条件年份
        myWidth: window.innerWidth,
        myHeight: window.innerHeight,
        // 给父组件传递的参数:
        isShowPigeonData: true,
        pwdType : '',
        formData: {
          name: ''
        },
        competitionForm: {
          name: '',
          dateValue: '',
          guildId: ''
        },
        competitionList: [], // 赛事列表
        showDataList: [],
        guildList: [],
        pagination: {
          pageSize: 10,
          currentPage: 1,
          totalData: 0
        },
        dialogFormVisible: false,
        currentRaceName: '',
        showConfigDialog: false,
        configurationData: {
          standbyDatetime: undefined,
          cst: undefined,
          rsut: undefined,
          rgli: undefined,
          rdui: undefined,
          lpl: undefined,
          lpgli: undefined,
          lpdui: undefined,
          nmst: undefined,
          nmet: undefined,
          ngli: undefined,
          ndui: undefined,
          cgli: undefined,
          cdui: undefined,
          rdut: undefined,
          gddt: undefined,
          rsud: undefined
        }
      }
    },
    created () {
        // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
        const lang = window.sessionStorage.getItem('lang');


// 设置 Vue I18n 的当前语言
this.$i18n.locale = lang;

// 打印当前语言
console.log("this.$i18n.locale", this.$i18n.locale);

  
         //TODO 删除缓存
         window.sessionStorage.removeItem('historicalPigeonRaceName')
      // 
      window.sessionStorage.removeItem('historicalTrackStartTime')
      window.sessionStorage.removeItem('historicalTrackEndTime')
  
  
    },
    beforeCreated () {
          //TODO 删除缓存
          window.sessionStorage.removeItem('historicalPigeonRaceName')
      // 
      window.sessionStorage.removeItem('historicalTrackStartTime')
      window.sessionStorage.removeItem('historicalTrackEndTime')
  
    },
    mounted () {
    // TODO 登录后才能或去赛事数据 
      this.pwdType = window.sessionStorage.getItem('pwdType')
      this.onQueryRaceList()
      this.onQueryPigeonGuildList()

      
    },
    methods: {
      getUniqueYearlyRaces(data) { // 筛选出每年唯一的赛事
    const uniqueRaces = [];
    const seen = new Set();
 
    data.forEach(item => {
        // 将 pigeonRaceDate 转换为年份
        const raceDate = new Date(item.pigeonRaceDate);
        const year = raceDate.getFullYear();
 
        // 组合年份和ID作为唯一标识符
        // const identifier = `${year}-${index}`;
        const identifier = `${year}`;
 
        // 检查该年份和ID组合是否已经存在
        if (!seen.has(identifier)) {
            seen.add(identifier);
            uniqueRaces.push({
                year: year,
                id: item.id
            });
        }
    });
 
    return uniqueRaces;
},
      onQueryRaceList () {
        queryRaceList(this.pwdType, this.pagination.currentPage, this.pagination.pageSize)
          .then((res) => {
            if (res.data.code === 0 && res.data.data) {
                this.competitionList = []
                this.showDataList = []
                // this.$watch('competitionList.length', (v1, v2) => {
                //   this.pagination.totalData = v1
                // })
                if (res.data.data[0]) {
                  res.data.data[0].forEach((item) => {
                    item.index = this.competitionList.length + 1
                    this.competitionList.push(item)
                  })
                }

                this.pagination.totalData = this.competitionList.length
                this.showDataList = this.competitionList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
                
              if(!this.showDataList.length) {
                this.showDataList = this.competitionList.slice(0, this.pagination.pageSize)
              }
              // console.log("this.showDataList", this.showDataList)
            }
          })
          .catch((e) => {})
      },
      inputYearChangeHandle(){
          console.log("选中的年份是", this.selectYear)
          this.onSubmitSearchFormData();
      },
      inputChangeHandle(e){
        // console.log("inputChangeHandle", e)
        this.onSubmitSearchFormData();
      },
      onSubmitSearchFormData () {
  this.showDataList = [];
  const searchName = this.formData.name && this.formData.name.trim();
  const selectedYear = this.selectYear;

  this.competitionList.forEach(item => {
    const raceYear = this.convertDateToString(item.pigeonRaceDate).substring(0, 4);
    const matchName = searchName ? item.pigeonRaceName.includes(searchName) : true;
    const matchYear = selectedYear ? raceYear.includes(selectedYear) : true;

    if (matchName && matchYear) {
      this.showDataList.push(item);
    }
  });

  // if (!this.showDataList.length) {
  //   this.showDataList = this.competitionList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage);
  // }
},
      convertDateToString (date) {
        if (date) {
          // window.alert(moment.tz(date, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss'))

          return moment.tz(date, 'Asia/Shanghai').format('YYYY-MM-DD')
          // return moment(date).utc(8).format("yyyy-MM-DD")
        } else {
          return ''
        }
      },
      handleSizeChange (pageSize) { // 分页:单页最大数量变化时的处理
        this.pagination.currentPage = 1
        this.pagination.pageSize = pageSize
        this.showDataList = this.competitionList.slice(0, pageSize)
      },
      handleCurrentChange (currentPage) { // 分页:当前页数变化时的处理
        this.pagination.currentPage = currentPage
        this.showDataList = this.competitionList.slice((currentPage - 1) * this.pagination.pageSize, currentPage * this.pagination.pageSize)
      },
          // TODO 跳转到直播模式
    onlineBtn(data){
      console.log("跳转到直播模式",data)
    //   // let routeData = this.$router.resolve({
    //   //   path: '/onlieHome'
    //   // })
    //   // TODO 存储session 赛事名称 和 开始时间 结束时间
    //  // 指定时区
    // moment.tz(timestamp, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');

      // let startTime =   moment.parseZone( data.pigeonRaceDate ).format("yyyy-MM-DD HH:mm:ss")
      // let endTime =   moment.parseZone(data.raceExpiryDate ).format("yyyy-MM-DD HH:mm:ss")

      let startTime =    moment.tz(data.pigeonRaceDate, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
      let endTime =    moment.tz(data.raceExpiryDate, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');

      console.log("开始时间",startTime)
      console.log("结束时间",endTime)
      //弹出信息数据
      // window.alert("获取数据:"+data.pigeonRaceDate)
      // window.alert("格式化后的开始时间:"+startTime+"结束时间:"+endTime)

      
      window.sessionStorage.setItem("raceId", data.id);
      window.sessionStorage.setItem('historicalPigeonRaceName', data.pigeonRaceName)
      window.sessionStorage.setItem('historicalTrackStartTime', startTime)
      window.sessionStorage.setItem('historicalTrackEndTime',endTime)
      // 时间搓保存到缓存
      window.sessionStorage.setItem("historicalTrackStartTimeStamp", new Date(startTime).getTime());
      window.sessionStorage.setItem("historicalTrackEndTimeStamp", new Date(endTime).getTime());
      
      window.sessionStorage.setItem("historicalTrackStarLat", data.starLat);
       window.sessionStorage.setItem("historicalTrackStarLon", data.starLon);

       window.sessionStorage.setItem("historicalTrackEndLon", data.endLon);
       window.sessionStorage.setItem("historicalTrackEndLat", data.endLat);


    //   // window.open(routeData.href, '')
    //   // TODO 给父组件传递数据 
      this.$emit('onEvent', this.isShowPigeonData)
    },
      clickTrackBtn (data){
        // console.log("数据是:",data,data.pigeonRaceName)
        
        // let routeData = this.$router.resolve({
        //   path: '/device/historicalTrack'
        // })
        // window.sessionStorage.setItem('historicalTrackDeviceImei', data.pigeonRaceName)
        // window.open(routeData.href, '')
  
        // 更改
        let routeData = this.$router.resolve({
          path: '/home'
        })
        // TODO 存储session 赛事名称 和 开始时间 结束时间 pigeonRaceDate
     
        // window.sessionStorage.setItem('historicalTrackDeviceImei', data.pigeonRaceName)
        // window.sessionStorage.setItem('historicalTrackStartTime', data.createDatetime)
        // window.sessionStorage.setItem('historicalTrackEndTime', data.pigeonRaceDate)
  
          // let startTime =   moment.utc( data.pigeonRaceDate).format("yyyy-MM-DD HH:mm:ss")
  
          // let endTime =   moment.utc(data.raceExpiryDate ).format("yyyy-MM-DD HH:mm:ss")


          let startTime =    moment.tz(data.pigeonRaceDate, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
      let endTime =    moment.tz(data.raceExpiryDate, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');

  
          // window.alert("发送请求钱开始时间:"+startTime+"结束时间:"+endTime)
          //   let endTime =   moment(data.pigeonRaceDate - 0 ).format("yyyy-MM-DD 23:59:59")
        window.sessionStorage.setItem("raceId", data.id);
        window.sessionStorage.setItem('historicalPigeonRaceName', data.pigeonRaceName)
        window.sessionStorage.setItem('historicalTrackStartTime', startTime)
        window.sessionStorage.setItem('historicalTrackEndTime',endTime)
        

       
        // 终点金纬度 longitude
  //       endLat
  // : 
  // 120.42185330498783
  // endLon
  // : 
  // 23.358101743572295
        // 起点金纬度
        
  // starLat
  // : 
  // 120.75020175398568
  // starLon
  // : 
  // 24.37612331771184
         window.sessionStorage.setItem("historicalTrackStarLat", data.starLat);
         window.sessionStorage.setItem("historicalTrackStarLon", data.starLon);
  
         window.sessionStorage.setItem("historicalTrackEndLon", data.endLon);
         window.sessionStorage.setItem("historicalTrackEndLat", data.endLat);
  
        window.open(routeData.href, '')
  
      },
      
      clickEditButton (data) {
        if(data.pigeonCommunityId) {
          let routeData = this.$router.resolve({
            path: "/competition/pigeonHouse",
          });
          window.sessionStorage.setItem("guildId", data.pigeonCommunityId);
          window.sessionStorage.setItem("guildName", data.communityName);
          window.open(routeData.href, "");
        }
      },
      clickManageButton (data) {
        if(data.pigeonCommunityId) {
          let routeData = this.$router.resolve({
            path: "/competition/competitionDevice",
          });
          window.sessionStorage.setItem("raceId", data.id);
          window.open(routeData.href, "");
        }
      },
      clickConfiguraButton (data) {
        this.modifyConfiguration(data.pigeonRaceName)
      },
      modifyConfiguration (raceName) {
        this.showConfigDialog = true
        this.currentRaceName = raceName
        this.configurationData = {}
        this.onQueryRaceConfig(raceName)
      },
      clickConfirmUpdateConfigurationButton (configuration) {
        if(configuration['id']) {
          updateRaceConfig(configuration).then(res => {
            if (res.data.code === 0) {
              this.showConfigDialog = false
              this.$message.success(this.$t('common.updateSuccess'))
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      },
      getConfValueByKey (key) {
        if (!this.configurationData || !this.configurationData[key]) return ''
        // console.log('configuration[' + key + ']: ' + this.configurationData[key])
        switch (key) {
          case 'standbyDatetime': // 开始待机时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rsut': // 设定预约开机时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DDTHH:mm') : ''
          case 'cst': // 配置数据修改时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rgli': // 定位频率
          case 'rdui': // 上传频率
            return this.configurationData[key]
          case 'lpl': // 低电量门限
            return this.configurationData[key]
          case 'lpgli': // 低电量模式定位频率
          case 'lpdui': // 低电量模式上传频率
            return this.configurationData[key]
          case 'nmst': // 第二段开始时间
          case 'nmet': // 第二段结束时间
            // console.log(moment(this.configurationData[key]).format('HH:mm'))
            return this.configurationData[key] ? moment(this.configurationData[key]).utc(0).format('HH:mm') : ''
          case 'ngli': // 第二段定位频率
          case 'ndui': // 第二段数据上传频率
          case 'cgli': // 第三段定位频率
          case 'cdui': // 第三段数据上传频率
            return this.configurationData[key]
          case 'rdut': // 数据上传启动时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD') : ''
          case 'gddt': // 开始下载星历时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rsud': // 比赛延迟开始时间
            return this.configurationData[key] / 60 || 0
        }
      },
      timeInputHandler (e) {
        switch (e.target.name) {
          case 'nmst':
          case 'nmet': {
            const timeArray = e.target.value.split(':')
            let timeStamp = 0
            for (let i = 0; i < timeArray.length; i++) {
              const item = parseInt(timeArray[i])
              if (i === 0) {
                timeStamp += item * 1000 * 60 * 60
              } else {
                timeStamp += item * 1000 * 60
              }
            }
            this.configurationData[e.target.name] = timeStamp
          }
            break
          case 'rdut':
          case 'rsut':
            if (e.target.value) {
              const timeStamp = new Date(e.target.value).getTime()
              this.configurationData[e.target.name] = timeStamp
            } else {
              this.configurationData[e.target.name] = undefined
            }
            break
        }
      },
      inputDelayTime (e) {
        if (!this.configurationData) return
        this.configurationData.rsud = e.target.value * 60
      },
      clickCreateButton () {
        this.competitionForm = {
          name: '',
          dateValue: '',
          guildId: ''
        }
        this.dialogFormVisible = true
      },
      clickDeleteButton (data) {
        this.showConfirmDialog(this.$t('common.notice'), this.$t('competition.msg.deleteTip'), () => {
          deleteCompetition(data.id).then(res => {
            if (res.data.code === 0) {
              this.$message.success(this.$t("common.deleteSuccess"))
                  for (let index = 0; index < this.competitionList.length; index++) {
                    let item = this.competitionList[index]
                    if (item.id === data.id) {
                      this.competitionList.splice(index, 1)
                      this.pagination.totalData = this.competitionList.length
                      break
                    }
                  }
                  this.showDataList = this.competitionList.slice(
                    this.pagination.pageSize * (this.pagination.currentPage - 1),
                    this.pagination.pageSize * this.pagination.currentPage
                  )
                  if (!this.showDataList.length && this.pagination.currentPage > 1) {
                    this.pagination.currentPage -= 1
                    this.showDataList = this.competitionList.slice(this.pagination.pageSize *(this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
                  }
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {})
        })
      },
      clickDialogConfirmButton () {
        if (!this.competitionForm.name || !this.competitionForm.name.trim()) {
          return this.$message.error(this.$t('competition.msg.inputNamePrompt'))
        }
        if (!this.competitionForm.dateValue) {
          return this.$message.error(this.$t('competition.msg.selectDate'))
        }
        if (!this.competitionForm.guildId) {
          return this.$message.error(this.$t('competition.selectGuild'))
        }
        this.dialogFormVisible = false
        createCompetition(this.competitionForm.name.trim(), this.competitionForm.dateValue.getTime(), this.competitionForm.guildId).then(res => {
          if (res.data.code === 0) {
            this.$message.success(this.$t('competition.msg.createSucceed'))
            this.onQueryRaceList()
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {})
      },
      updateRaceDevice (imei, raceId) { // 更新参赛设备,传入ID表示参加该ID对应的比赛，不传入表示从所有比赛中删除剔除该设备
        updateCompetitionDevice(imei, raceId).then(res => {
          if (res.data.code === 0) {
            this.$message.success('succeed')
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {})
      },
      showConfirmDialog (title, content, confirmCallback) {
        this.$confirm(content, title, {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warnning'
        }).then(confirmCallback).catch(() => {})
      },
      onQueryPigeonGuildList () {
        queryRolesList(1, '')
          .then((res) => {
            if (res.data.code === 0 && res.data.data) {
              if (res.data.data[0]) {
                this.guildList = []
                res.data.data[0].forEach((item) => {
                  this.guildList.push(item)
                });
              }
            }
          })
          .catch((e) => {})
      },
      onQueryRaceConfig (raceName) {
        queryRaceConfig(raceName).then(res => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data[0] && res.data.data[0][0]) {
              this.configurationData = res.data.data[0][0]
            }
          }
        })
      },
    }
  }
  </script>
  <style lang="less" scoped>


/** 关闭样式 */
.closePlay2 {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    height: 30px;
    background: #000;
    opacity: 0;
    border-radius: 50%;
    transition: all .3;
    z-index: 102;
}


.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}


  ::v-deep{

    .el-card {
    box-shadow: 4px 3px 8px 3px #37373775 !important;
    }
    // .el-input__inner{
    //   border: 1px solid var(--black-color) !important;
    //   color: var(--black-color);
    // }

      .el-pagination__total{
      color: #fff;
    }
    .el-pagination__jump {
     
     color: #fff;
    }

    .child-table .el-table__body tr.hover-row.current-row>td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped.current-row>td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped.selection-row>td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped>td.el-table__cell, .el-table__body tr.hover-row.selection-row>td.el-table__cell, .el-table__body tr.hover-row>td.el-table__cell {
    background-color: #ffffff00;
    }
       // 鼠标移上去
       .child-table .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #ffffff00;
    }
  }
  
  
//  卡片样式
.container-row {
  /* 你可以根据需要调整高度 */
  // height: 500px;
  overflow-y: auto; /* 启用垂直滚动条 */
  border-bottom: 1px solid #e6e6e6;

}
  
  </style>
  