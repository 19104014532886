import { render, staticRenderFns } from "./pigeonListData.vue?vue&type=template&id=78c0b756&scoped=true"
import script from "./pigeonListData.vue?vue&type=script&lang=js"
export * from "./pigeonListData.vue?vue&type=script&lang=js"
import style0 from "./pigeonListData.vue?vue&type=style&index=0&id=78c0b756&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c0b756",
  null
  
)

export default component.exports