<template>

<div style="height: 90%;">
    <div class="pigeon-list-data">
        <h2>模拟数据</h2>
        <div>{{ pigeonHomeListData }}</div>

        <!-- 
       xs	<768px 响应式栅格数或者栅格属性对象 手机
       sm	≥768px 响应式栅格数或者栅格属性对象 网页
      -->
        <el-row :gutter="12">
            <el-col :sm="12" :xs="12">
                <!-- 请选择鸽舍 -->
                <el-select v-model="houseId" clearable :placeholder="$t('phoneLanguage.selectPigeonHome')" @change="inputRaceIdChangeHandle">
                    <el-option v-for="item in pigeonHomeListData" :key="item.id" :label="item.houseId"
                        :value="item.houseId">
                    </el-option>
                </el-select>
            </el-col>

            <el-col :sm="12" :xs="12">
                 <!-- 请输入鸽环号 -->
                <el-input clearable prefix-icon="el-icon-search" @input="inputSelectPigeonHomeIdChangeHandle"
                    v-model="selectPigeonHomeId" :placeholder="$t('phoneLanguage.inputPigeonRingNumber')"></el-input>
            </el-col>
        </el-row>

        <!--鸽舍列表数据-->
        <el-table size="mini" :data="showDataList" ref="multipleTable" :border="true" class="child-table"
            :row-key="getRowKeys" @selection-change="handleSelectionChange"
            :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column type="selection" width="55" :reserve-selection="true">
            </el-table-column>

            <!-- 排名 -->
            <el-table-column :label="$t('phoneLanguage.ranking')" prop="index" width="80">
            </el-table-column>

            <!-- 鸽环号 -->
            <el-table-column prop="deviceImei" :label="$t('phoneLanguage.pigeonRingNumber')"></el-table-column>

        </el-table>

        </div>
    </div>
</template>

<script>
import { queryRaceHouseList } from '@/api/requestUtils'

export default {
    data() {
        return {
            raceId: '', // 赛事ID
            houseId: '', // 鸽舍ID
            selectPigeonHomeId: '', // 鸽舍ID
            showDataList: [], // 筛选出来的显示数据列表
            pigeonDataList: [
            {
                    deviceImei: '8634567890',
                    index: 1,
                },
                {
                    deviceImei: '8634567891',
                    index: 2,
                },
                {
                    deviceImei: '8634567892',
                    index: 3,
                },
                {
                    deviceImei: '8634567893',
                    index: 4,
                },
                {
                    deviceImei: '8634567894',
                    index: 5,
                },
                {
                    deviceImei: '8634567895',
                    index: 6,
                },
                {
                    deviceImei: '8634567896',
                    index: 7,
                },
                {
                    deviceImei: '8634567897',
                    index: 8,
                },
                {
                    deviceImei: '8634567898',
                    index: 9,
                },
                {
                    deviceImei: '8634567899',
                    index: 10,
                },
                {
                    deviceImei: '8634567900',
                    index: 11,
                },
                {
                    deviceImei: '8634567901',
                    index: 12,
                },
                {
                    deviceImei: '8634567902',
                    index: 13,
                },
                
            ], //  鸽舍列表数据
            pigeonHomeListData: [   //鸽舍列表数据
                
            ]

        }
    },
    mounted() {


        // 模拟鸽子数据加载完成，直接赋值给 showDataList 变量
        this.showDataList = this.pigeonDataList

    },
    created() {
        const raceId = sessionStorage.getItem('raceId')
        console.log("赛事id数据是：",raceId)

        this.raceId = 33;
        if (this.raceId) {
            this.onQueryRaceDeviceList()
        }
    },
    methods: {
        // TODO 获取数据鸽舍类别数据 等待数据加载完成
        async onQueryRaceDeviceList() {
            await queryRaceHouseList(this.raceId)
                .then((res) => {
                    if (res.data.code === 0 && res.data.data) {
                        console.log("鸽舍数据是：",res.data.data)

                        res.data.data[0].forEach((item) => {
                        
                            // 组件鸽舍列表数据
                            this.pigeonHomeListData.push({
                                id: item.id,
                                houseId: item.houseId,
                                raceId: item.raceId,
                               
                            });

                            // 默认选中第一个鸽舍
                            if (this.pigeonHomeListData.length > 0) {
                                this.houseId = this.pigeonHomeListData[0].houseId;
                            }
                          
                         })
                        
                    }
                  

                })
                .catch((e) => { })
        },
        inputRaceIdChangeHandle() {
            console.log("根据鸽舍id 查询鸽子列表---", this.houseId)
            // 模拟数据 根据鸽舍id 查询鸽子列表
            if(this.houseId == 99){
                this.pigeonDataList = [];
            this.pigeonDataList = [
                {
                    deviceImei: '11111111（失格）',
                    index: 1,
                },
                {
                    deviceImei: 'Uy0312',
                    index: 2,
                },
                {
                    deviceImei: '22222222',
                    index: 3,
                }
            ];

            this.showDataList = [];
            this.pigeonDataList.forEach(item => {

                this.showDataList.push(item);

            });

            }else{
            this.pigeonDataList = [];
            this.pigeonDataList = [
            {
                    deviceImei: '8634567890',
                    index: 1,
                },
                {
                    deviceImei: '8634567891',
                    index: 2,
                },
                {
                    deviceImei: '8634567892',
                    index: 3,
                },
                {
                    deviceImei: '8634567893',
                    index: 4,
                },
                {
                    deviceImei: '8634567894',
                    index: 5,
                },
                {
                    deviceImei: '8634567895',
                    index: 6,
                },
                {
                    deviceImei: '8634567896',
                    index: 7,
                },
                {
                    deviceImei: '8634567897',
                    index: 8,
                },
                {
                    deviceImei: '8634567898',
                    index: 9,
                },
                {
                    deviceImei: '8634567899',
                    index: 10,
                },
                {
                    deviceImei: '8634567900',
                    index: 11,
                },
                {
                    deviceImei: '8634567901',
                    index: 12,
                },
                {
                    deviceImei: '8634567902',
                    index: 13,
                },
            ];

            this.showDataList = [];
            this.pigeonDataList.forEach(item => {

                this.showDataList.push(item);

            });
            }

        },
        inputSelectPigeonHomeIdChangeHandle(e) {
            console.log("输入的是", e)

            this.showDataList = [];
            const searchName = e.trim();

            this.pigeonDataList.forEach(item => {

                const matchName = searchName ? item.deviceImei.includes(searchName) : true;


                if (matchName) {
                    this.showDataList.push(item);
                }
            });


        },
        
        //TODO 点击表格选中事件
        handleSelectionChange(val) {
            console.log('选择表格', val)

            // TODO 测试发请求
            // 子组件发送数据
            this.$emit('onEventigeonList1', val)
        },
        // 记住已经选中的数据
        getRowKeys(row) {
            return row.index;
        },
    }
}
</script>

<style lang="less" scoped>
.pigeon-list-data{
  height:100%;
  padding: 10px;
   overflow-y: auto; /* 允许在Y轴方向上滚动 */  
   border: 1px solid var(--black-color); /* 可选，用于清楚地看到容器边界 */  
}
.deviceList {
  width: 100%;
  height: 98% !important;
  float: left;
  background-color: #052531;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.deviceList.collapseList {
  display: none;
}

.totalText {
  font-size: 15px;
  color: var(--black-color);
}

.statusOnline {
  border: 1px solid red;
  width: 4px;
  height: 4px;
  background-color: red;
  margin-top: 6px;
  border-radius: 3px;
}

.statusRun {
  border: 1px solid green;
  width: 4px;
  height: 4px;
  background-color: green;
  margin-top: 6px;
  border-radius: 3px;
}

.statusOffline {
  border: 1px solid #fcd820;
  width: 4px;
  height: 4px;
  background-color: #fcd820;
  margin-top: 6px;
  border-radius: 3px;
}

.searchInput {
  width: 94%;
  margin-top: 4px;
}

.tabWrap {
  height: calc(100% - 160px);

  .allTree {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /deep/ .el-tabs__content {
    flex: 1;
  }

  .el-tab-pane {
    height: 100%;
  }



  .el-tree {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    background-color: #052531;
    color: #ccc;
  }

  /deep/ .el-tree-node__children {
    overflow: visible !important;
  }

  // :deep .el-tree-node{
  //   height: 100%;
  //   // 横向滚动条
  //   // el-tree-node__children的样式默认overflow:hidden
  //   // 继承宽度，当宽度超出时隐藏，固不显示横向滚动
  //   .el-tree-node__children{
  //     overflow: visible;
  //   }

  // }
  // :deep .el-tree-node__children{
  //     overflow: visible !important;
  //   }
  // ::v-deep {
  //高宽分别对应横竖滚动条的尺寸
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  //定义滚动条轨道 内阴影 圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  //定义滑块 内阴影 圆角
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #3498dc;
  }

}

::v-deep {


  .child-table    
  .el-table,
  .el-table__expanded-cell {
    background-color: #ffffff00;
  }

  .child-table.el-table th.el-table__cell {
    background-color: #ffffff00;
  }

  .child-table.el-table thead {
    color: #000000;
  }

  .child-table.el-table tr {
    background-color: #ffffff00;
    color: #000000;
  }

  .child-table.el-table__empty-text {

    color: #000000;
  }



}

/**移动端样式  */
@media screen and (max-width: 768px) {

//   ::v-deep {
//     // .el-table th.el-table__cell {
//     //     user-select: initial;
//     .el-pagination {
  
//   position: absolute;
//   bottom: 10 !important;
// }

        // }
        .child-table.el-table {
    margin-top: 1px;
    height: 408px !important;
    ;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .child-table.el-table,
  .el-table__expanded-cell {
    background-color: #ffffff00;
  }

  .child-table.el-table th.el-table__cell {
    background-color: #ffffff00;
  }

  .child-table.el-table thead {
    color: #000000;
  }

  .child-table.el-table tr {
    background-color: #ffffff00;
    color: #000000;
  }

  .child-table.el-table__empty-text {

    color: #000000;
  }



  // }
}




</style>