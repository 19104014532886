<template>
    <div style="height: 100%;">
        <!-- ˙直播平台页面 -->

        <!-- cesium 地图组件 123{{  list }} -->



        <div id="cesiumContainer" style="height: 100%; width: 100%;"></div>


        <!-- 排名数据 -->
        <div v-if="isShowRanking">

            <div class="echelonBox on" data-v-e6b6cb14="" @mouseover="isShowRankingHovered = true"
                @mouseleave="isShowRankingHovered = false" :style="{
                    backgroundColor: isShowRankingHovered ? '#23222278' : 'rgba(255, 255, 255, 0.1)'
                }">
                <!-- <div class="closePlay2 main_flex_ar" :style="{
                    opacity: isShowRankingHovered ? 1 : 0,
                    backgroundColor: isShowRankingHovered ? '#ffffff66' : 'rgba(255, 255, 255, 0.1)'
                }">
                    <i class="el-icon" @click="closeRanking">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                        </svg>
                    </i>
                </div> -->
                <!-- <div class="infoBoxTit main_flex">
                    <div class="titBoxImg">
                        <img style="width: 30px;height: 30px;margin-right: 15px" src="/mapGLB/tier.png" alt="赛鸽排名">
                    </div>赛鸽排名
                </div> -->

                <!-- TODO 不要边框 border -->

                <div>

                    <!-- <span>
                    {{ endText }}
                {{ startText }}
                {{ startText }} =====
                {{ $t('phoneLanguage.startPoint') }}
                {{ $t('phoneLanguage.endPoint') }}
                {{ $t('phoneLanguage.kilometer') }}
            </span> -->
                    <!-- 搜索框独立成行 请输入鸽环号/名次 -->
                    <el-input v-model="searchVal"
                        :placeholder="$t('phoneLanguage.inputEnterRankOrpigeonRingNumberSearchHint')"
                        class="table-search-input" style="width: 100%; max-width: 600px; margin: 0 auto;" />
                </div>

                <el-table size="mini" :data="filteredData" @row-click="handleCurrentChange"
                    :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }" height="222" style="width: 100%">


                    <!-- 排名 -->
                    <el-table-column :label="$t('phoneLanguage.ranking')" prop="index" width="50">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center;">
                                <div
                                    :class="[scope.row.index === 1 ? 'index_one' : scope.row.index === 2 ? 'index_two' : scope.row.index === 3 ? 'index_three' : '']">
                                    {{ scope.row.index }}
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 5px;">
                                    <img class="index_common"
                                        :src="scope.row.index === 1 ? '/mapGLB/gold.png' : scope.row.index === 2 ? '/mapGLB/silver.png' : scope.row.index === 3 ? '/mapGLB/copper.png' : ''"
                                        v-if="scope.row.index <= 3" alt="" />
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <!-- 鸽环号 -->
                    <el-table-column :label="$t('phoneLanguage.pigeonRingNumber')" prop="deviceImei">
                    </el-table-column>

                    <!-- TODO 其他列可以根据需要添加 跳转轨迹详情  -->
                    <!--
                    <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="handleEdit(scope.row)">查看</el-button>
                        
                    </template>
                    </el-table-column>
                    -->

                    <!-- <el-table-column label="昵称" prop="deviceName">
                    </el-table-column> -->
                    <!-- <el-table-column label="离终点（km）" prop="endDistance">
                    </el-table-column> -->
                </el-table>


            </div>
        </div>


        <!-- 轨迹详情数据抽屉展示 -->
        <el-drawer title="轨迹详情" :visible.sync="showTrackTable" direction="btt" :modal="false" :with-header="false"
            @close="handleDrawerClose" size="60%">

            <div style="color: white;" class="drawer_font_size">

                <!-- 
       xs	<768px 响应式栅格数或者栅格属性对象 手机
       sm	≥768px 响应式栅格数或者栅格属性对象 网页
      -->

                <el-row>
                    <el-col :sm="12" :xs="16">
                        <!-- 当前设备 -->
                        <div>{{ $t('computerLanguage.currentDevice') }}：{{ currentSelectDevice }}</div>
                        <!-- 起点时间： -->
                        <div>{{ $t('computerLanguage.startPointTime') }}：{{ currentSelectDeviceStartTime }}</div>
                        <!-- 终点时间： -->
                        <div>{{ $t('computerLanguage.endPointTime') }}：{{ currentSelectDeviceEndTime }}</div>
                        <!-- 飞行时长： -->
                        <div>{{ $t('computerLanguage.flightDuration') }}：{{ currentSelectDeviceFlightduration }}</div>
                    </el-col>

                    <el-col :sm="12" :xs="8" style="text-align: right;">
                        <!-- 轨迹详情 Track information-->
                        <el-button type="primary" @click="trackInfor">轨迹证书</el-button>
                    </el-col>
                </el-row>


                <hr>


                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <!-- 平均分速 (m/Min): -->
                        <div>{{ $t('computerLanguage.averageSpeed_m_min') }}</div>
                        <div>{{ averageSpeed }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <!-- 最高分速 (m/Min): -->
                        <div>{{ $t('computerLanguage.maxSpeed_m_min') }}</div>
                        <div>{{ maxSpeed }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <!-- 平均高度 (m): -->
                        <div>{{ $t('computerLanguage.averageAltitude_m') }}</div>
                        <div>{{ averageAltitude }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <!-- 最大高度 (m): -->
                        <div>{{ $t('computerLanguage.maxAltitude_m') }}</div>
                        <div>{{ maxAltitude }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <!-- 实际距离 (km): -->
                        <div>{{ $t('computerLanguage.actualDistance_km') }}</div>
                        <div>{{ actualDistance }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <!-- 实际分速 (m/Min): -->
                        <div>{{ $t('computerLanguage.actualSpeed_m_min') }}</div>
                        <div>{{ actualSpeed }}</div>
                    </div>
                </div>

                <div class="dynamics_data">
                    <div class="dynamics_data_info">
                        <!-- 直线距离 (km): -->
                        <div>{{ $t('computerLanguage.straightLineDistance_km') }}</div>
                        <div>{{ lineDistance }}</div>
                    </div>
                    <div class="dynamics_data_info">
                        <!-- 直线分速 (m/Min): -->
                        <div>{{ $t('computerLanguage.straightLineSpeed_m_min') }}</div>
                        <div>{{ lineSpeed }}</div>
                    </div>
                </div>


                <hr>
            </div>

            <el-table :data="pigeonTableData" :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                :cell-style="{ textAlign: 'center' }" height="250" border style="width: 100%">

                <!-- 分段资料 -->
                <el-table-column :label="$t('computerLanguage.segmentData')">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }} <!-- $index 是从 0 开始的，所以加 1 以符合常规序号习惯 -->
                    </template>
                </el-table-column>

                <!-- 时间 -->
                <el-table-column :label="$t('computerLanguage.time')">
                    <template slot-scope="scope">{{ formatGpsTime(scope.row.gpsTime) }}</template>
                    <!-- <template slot-scope="scope">{{ scope.row.gpsTime }}</template> -->
                </el-table-column>


                <!-- 累积时间 -->
                <el-table-column prop="accumulateDuration" :label="$t('computerLanguage.accumulateTime')">
                    <template slot-scope="scope">{{ formatDuration(scope.row.accumulateDuration) }}</template>

                </el-table-column>
                <!-- 距离 m-->
                <el-table-column prop="accumulateOdometer" :label="$t('computerLanguage.distance_m')">
                    <!-- calculateDistance -->
                    <!-- <template slot-scope="scope">
                            {{ calculateDistance(scope.row.lng,scope.row.lat) }}
                        </template>
    -->
                </el-table-column>

                <!-- 海拔 m-->
                <el-table-column prop="altitude" :label="$t('computerLanguage.altitude_m')">
                </el-table-column>

                <!-- 速度 m/s -->
                <el-table-column :label="$t('computerLanguage.speed_m_s')">
                    <template slot-scope="scope">
                        {{ calculateSpeed(scope.row.accumulateOdometer, scope.row.accumulateDuration) }}
                    </template>
                </el-table-column>


            </el-table>

            <!-- <el-table :data="gridData">
      <el-table-column property="date" label="日期" ></el-table-column>
      <el-table-column property="name" label="姓名" ></el-table-column>
      <el-table-column property="address" label="地址"></el-table-column>
    </el-table>
   -->


        </el-drawer>



        <!-- 按钮底部数据 -->
        <div id="test" class="botOtherBox" v-if="false">
            <div class="botOtherItem" @click="switchAnimation()">切换</div>
            <div class="botOtherItem" @click="startAnimation()">播放</div>
            <div class="botOtherItem" @click="pauseAnimation()">暂停</div>
            <div class="botOtherItem" @click="resetAnimation()">重置</div>
            <div class="botOtherItem" @click="speedUpAnimation()">加速</div>
            <div class="botOtherItem" @click="speedDownAnimation()">减速</div>


        </div>

        <!-- 地图切换按钮-->
        <div class="mapSwitchBox">

            <div class="mapSwitch" @click="goGoogleMap()">
                <img class="mapSwitchItem" src="/mapGLB/icon-map-google.png">
            </div>


            <div class="mapSwitch" @click="toggleMapMode()">
                <img class="mapSwitchItem" src="/mapGLB/3d-icon.png" alt="3D">
            </div>

            <!-- 留地 图影像切换 隐藏显示点位点 -->
            <div class="mapSwitch" @click="ShowbaseLayerPicker()" v-if="false">
                <img class="mapSwitchItem" :src="isShowbaseLayerPicker ? '/mapGLB/3d-icon.png' : '/mapGLB/2d-icon.png'"
                    alt="Map Mode">
            </div>

            <!-- 显示排名 -->
            <div class="mapSwitch" @click="showPigeonRankingPopup()">
                <img class="mapSwitchItem" :src="isShowRanking ? '/mapGLB/ranking_on.png' : '/mapGLB/ranking_off.png'"
                    alt="Map Mode">
            </div>

            <!-- 隐藏显示点位点 -->
            <div class="mapSwitch" @click="showMarkerMapMode()">
                <img class="mapSwitchItem" :src="markerMapMode ? '/mapGLB/marker_on.png' : '/mapGLB/marker_off.png'"
                    alt="隐藏/显示标记点">
            </div>

            <!-- 是否全屏 -->

            <div class="mapSwitch" @click="isFullScreen()">
                <img class="mapSwitchItem"
                    :src="isShowFullscreenButton ? '/mapGLB/fullScreen_on.png' : '/mapGLB/fullScreen_off.png'"
                    alt="全屏/不全屏">
            </div>



        </div>

        <!-- 轨迹详情 -->
        <div class="mapGameBox">
            <!-- 比赛数据 按钮 alt="比赛数据"  @click="table = true"  -->
            <div class="mapSwitch" @click="isShowTrack()">
                <img class="mapSwitchItem"
                    :src="isShowTrackDataButton ? '/mapGLB/trackData_on.png' : '/mapGLB/trackData_off.png'">
            </div>
        </div>

    </div>
</template>

<script>
// 所有数据 
// import MyDataSource from '@/assets/data/datatest.json';

// 简单数据
// import MyDataSource from '@/assets/data/test.json';

// 第三方组件弹窗 
import CustomPopup from "cesium_custom_popup";
// 1 ok 867585332211376
// import TrajectoryData from '@/assets/data/pigeon_1.json';
// 8 ok
// import TrajectoryData from '@/assets/data/pigeon_5.json';
// 15 ok
// import TrajectoryData from '@/assets/data/pigeon_20.json';

// 引入轨迹数据 全部鸽子
// import TrajectoryData from '@/assets/data/datatest.json';

// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';

export default {

    props: ['onlietestData', 'colseload', 'sendisHide', 'sendShowPigeons', 'sendDeviceImeiArray'],
    watch: {
        // TODO 监听 数据 变化 加载实时数据
        // 
        // onlietestData(newVal, oldVal) {


        // if (newVal && newVal.length > 0) {
        //     console.log("数据:",newVal);
        //     // this.list.push(newVal.positions);
        //     this.modelAnimate(newVal,'./mapGLB/gezia.glb');

        //   } else {
        //     // 处理空数据或未定义的情况
        //     console.log("test轨迹数据为空或未定义");
        //     // this.drawPolyline(newVal);
        //     console.log("数据:",newVal.length);
        //   }

        // }

        // TODO 监听 数据 变化
        sendDeviceImeiArray(newVal, oldVal) {
            // console.log(newVal.length,"监听 数据 变化:",newVal);
            this.hideShowDevice(newVal);

        },
        // 隐藏实体 移除实体
        sendisHide(newVal, oldVal) {
            this.removeAllEntity();

        },

        // 显示实体
        sendShowPigeons(newVal, oldVal) {
            this.showAllEntity();
        }

    },
    data() {
        return {

            showTrackTable: false, // 显示隐藏轨迹数据弹窗



            // 表单点击选中的行
            currentRow: null,
            currentRowKey: null, // 用于存储当前选中行的唯一标识
            searchVal: '', // 搜索框内容

            popup: null,    // 弹窗对象
            // 标记点
            // 120.42185330498783
            // 终点经度
            // 
            markerData: {
                "type": "FeatureCollection",

                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            120.42185330498783,
                            23.358101743572295
                        ]
                    },
                    "properties": {
                        "title": "Unnamed",
                        "marker-color": "#B9EB14"
                    }
                },
                ]
            },

            isBoxVisible: false, // 样式是否显示弹框
            isHovered: false,   // 样式是否显示弹框
            isEchelonVisible: false, // 样式是否显示弹框
            isShowIoc: false, // 样式是否显示弹框

            // 是否显示排名数据
            isShowRanking: true,
            isShowRankingHovered: false,


            // 终点
            endText: this.$t('phoneLanguage.endPoint'),
            startText: this.$t('phoneLanguage.startPoint'),
            // 公里
            kilometerText: this.$t('phoneLanguage.kilometer'),

            EchelonTime: "", // 当前时间

            currentSelectDevice: "",// 当前选中的设备
            currentSelectDeviceStartTime: "",// 当前选中的设备起点时间
            currentSelectDeviceEndTime: "",// 当前选中的设备终点时间
            currentSelectDeviceFlightduration: "",// 当前选中的设备飞行时长

            // 平均分速
            averageSpeed: 0, // 平均速度
            // 最高分速
            maxSpeed: 0, // 最高速度
            averageAltitude: 0, // 平均海拔
            // 最大高度
            maxAltitude: 0, // 最大高度
            // 实际距离
            actualDistance: 0, // 实际距离
            // 实际分速
            actualSpeed: 0, // 实际速度
            // 直线距离
            lineDistance: 0, // 直线距离
            // 直线分速
            lineSpeed: 0, // 直线速度



            echelonData_0_20: 0, // 20公里以下  
            echelonData_20_35: 0, // 
            echelonData_35_50: 0, // 35公里以上
            echelonData_50_65: 0, // 50公里以上
            echelonData_65_80: 0, // 65公里以上
            echelonData_80: 0, // 80公里以上

            circleRadius_30: 20000.0, // 米作为单位，这里 红
            circleRadius_100: 35000.0, // 使用米作为单位，这里 蓝色 
            circleRadius_200: 50000.0, // 使用米作为单位，这里 绿色
            circleRadius_300: 65000.0, // 半径为使用米作为单位，这里 紫色
            circleRadius_400: 80000.0, // 半径，这里 黄

            circleCenter_30: 0,
            circleCenter_100: 0,
            circleCenter_200: 0,
            circleCenter_300: 0,
            circleCenter_400: 0,
            //   tableData: TrajectoryData,
            tableData: [],
            pigeonTableData: [],
            tableData2: [{
                index: 1,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4049,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "鸽子",
            },
            {
                index: 2,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4501,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219050",
                deviceName: "867585332",
            },
            {
                index: 3,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4503,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219239",
                deviceName: "大鸽大1",
            },
            {
                index: 4,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4504,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },
            {
                index: 5,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 4800,
                altitude: '25', // 海拔高度
                odometer: '4500',
                duration: '300',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },
            {
                index: 6,
                gpsTime: "2023-11-08 09:33:20",
                accumulateOdometer: 82787,
                accumulateDuration: 450,
                altitude: '25', // 海拔高度
                odometer: '600',
                duration: '6',
                speed: '0',
                deviceImei: "867585332219049",
                deviceName: "867585332219049",
            },

            ],

            // TODO 按钮事件
            currentMapMode: true, // 初始模式设为3D
            markerMapMode: true, // 初始显示标记点
            isShowbaseLayerPicker: true, // 初始影像显示图层
            isShowFullscreenButton: true, // 初始显示全屏按钮
            isShowTrackDataButton: true, // 初始显示轨迹数据按钮

            statisticsInterval: null, // 用于存储定时器的引用


            eventStartTime: 0, //   开始时间
            eventEndTime: 0,  // 结束时间
            eventName: '',  // 赛事名称
            currentIndex: -1, // 切换实体当前索引

            startLat: 0,  // 开始纬度
            startLng: 0,  // 开始经度
            endLat: 0,  // 结束纬度
            endLng: 0,  // 结束经度

            startTime: 0,  // 开始时间
            stopTime: 0,  // 结束时间
            viewer: null,
            mLatLng: [],
            //    id:this.list[index][0].deviceImei+"--->"+this.list[index][0].deviceName,
            // TrajectoryData   MyDataSource
            list: [],
            processedTableData: [], // 用于存储处理后排名的数据
            // 用于存储已生成的颜色
            generatedColors: new Set(),
            // 存储当前唯一的颜色
            uniqueColor: '',

            list2:
                [
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        deviceImei: "1234567890",
                        deviceName: "测试1",
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                    },
                    {
                        lng: 116.2821,
                        lat: 39.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699375800000,
                    },
                    {
                        lng: 115.497402,
                        lat: 39.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699376900000,
                    },],

                    // 2 
                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "8888888",
                        deviceName: "测试2",
                    },
                    {
                        lng: 117.3821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699378800000,
                    },
                    {
                        lng: 118.507402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699374800000,
                    },],
                    // 3

                    [{
                        lng: 116.405419,
                        lat: 39.918034,
                        height: 0,
                        // sjtime: "2022-02-22T08:25:57.000+0800",
                        time: 1699372800000,
                        deviceImei: "999999",
                        deviceName: "鸽子3",
                    },
                    {
                        lng: 117.2821,
                        lat: 38.918145,
                        height: 2000,
                        // sjtime: "2022-02-22T08:26:27.000+0800",
                        time: 1699376800000,
                    },
                    {
                        lng: 118.497402,
                        lat: 38.344641,
                        height: 60,
                        // sjtime: "2022-02-22T08:26:57.000+0800",
                        time: 1699377800000,
                    },],



                ],
        };
    },
    // 要关闭的时候
    beforeDestroy() {
        // 销毁地图
        if (window.viewer2DP) {
            // 移除监听器
            window.viewer2DP.clock.onTick.removeEventListener(this.mOnTick);

            window.viewer2DP.entities.removeAll(); // 移除所有实体
            window.viewer2DP.destroy();
            window.viewer2DP = null;

        }

        // 销毁地图 2D 地图
        // if (window.viewer2DP2D) {
        //     window.viewer2DP2D.entities.removeAll(); // 移除所有实体
        //     window.viewer2DP2D = null;

        // }


        // 在组件销毁之前清理定时器
        if (this.statisticsInterval) {
            clearInterval(this.statisticsInterval);
            this.statisticsInterval = null;
        }

        // // 移除计时器

        //  if(this.mOnTick){
        //     console.log("移除计时器 ");
        //  }


    },

    // destroyed() {
    //     if (window.viewer2DP) {
    //         window.viewer2DP.entities.removeAll(); // 移除所有实体
    //         window.viewer2DP = null;
    //     }
    // },
    created() {
        // 获取当前系统语言
        const language = navigator.language || navigator.userLanguage;
        // 判断语言
        let systemLanguage;
        if (language.startsWith('zh')) {
            // 判断是简体中文还是繁体中文
            if (language === 'zh-CN' || language === 'zh-Hans' || language === 'zh') {
                systemLanguage = 'zh-CN';
            } else if (language === 'zh-TW' || language === 'zh-Hant') {
                systemLanguage = 'zh-TW';
            } else {
                systemLanguage = 'en-US';
            }
        } else if (language.startsWith('en')) {
            systemLanguage = 'en-US';
        } else {
            systemLanguage = 'en-US';
        }

        // console.log('系统语言是:', systemLanguage);

        // 打印当前系统语言
        // console.log("打印当前系统语言language", language);
        window.sessionStorage.setItem('lang', systemLanguage);

        // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
        const lang = window.sessionStorage.getItem('lang');

        // 设置 Vue I18n 的当前语言
        this.$i18n.locale = lang;



        // 打印当前语言
        console.log("2D Vue I18n 的当前语言", this.$i18n.locale);
        //测试弹窗输出单前系统语言
        // window.alert(this.$i18n.locale);

        // TODO 测试获取轨迹数据
        const sessionPigeonData = window.sessionStorage.getItem('pigeonTrajectoryData') || [];
        if (sessionPigeonData.length > 0) {
            const pigeonData = JSON.parse(sessionPigeonData);
            this.tableData = pigeonData;
            this.list = pigeonData;

            console.log("this.pigeonData", pigeonData);
        } else {
            //
            console.log("测试获取轨迹数据-->", sessionPigeonData);
        }


        //         比赛时间：
        // 2023-11-08 08:30:00
        // 创建日期
        // 2023-09-12 00:00:00

        // 有效时间：
        // 2023-11-08 11:30:00
        // 起点纬度：
        // 120.75020175398568
        // 起点经度：
        // 24.37612331771184

        // 终点纬度度
        // 120.42185330498783
        // 终点经度
        // 23.358101743572295


        //  TODO 从缓存获取赛事名称 开始 和结束
        // this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || ''
        // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
        // this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''

        // this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '0'
        // this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '0'
        // this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '0'
        // this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '0'

        // this.startTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime)); // 中国时间 开始时间
        // this.stopTime = Cesium.JulianDate.fromDate(new Date(this.eventEndTime));  // 中国时间 结束时间 1730981637000

        // console.log("开始时间：",new Date(this.eventStartTime),"结束时间：",new Date(this.eventEndTime))


        // console.log('地图了----赛事信息:', this.eventName,this.eventStartTime,this.eventEndTime)
        //         开始时间： 2023-11-08 08:30:00 结束时间： 2023-11-08 11:30:00
        // 开始经纬度： 120.75020175398568 开始经纬度： 24.37612331771184
        // 结束经纬度： 120.42185330498783 结束经纬度： 23.358101743572295
        // 开始时间： Pt 结束时间： Pt


        this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || ''
        this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || '2023-11-08 08:30:00'
        this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || '2023-11-08 11:30:00'

        this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '24.37612331771184'
        this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '120.75020175398568'
        this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '120.42185330498783'
        this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '23.358101743572295'

        this.startTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime)); // 中国时间 开始时间
        this.stopTime = Cesium.JulianDate.fromDate(new Date(this.eventEndTime));  // 中国时间 结束时间 1730981637000

    },

    mounted() {
        // 初始化时生成一个唯一颜色
        this.generateUniqueColor();

        // console.log("轨迹数据2:",this.mLatLng2.flat());
        // console.log("轨迹数据:",this.mLatLng.flat());
        // cesium 地图初始化
        this.initCesiumMap();

        // 添加模型
        // this.addModel( '/mapGLB/Cesium_Air.glb',5000);
        // this.addModel('/mapGLB/pigeon1.glb', 5000);
        // console.log("闯过的指轨迹数据coordinates:",this.coordinates);
        // console.log("闯过的指轨迹数据test:",this.test);


        // TODO 获取假的数据
        this.processTableData();

        // 表格默认选中第一个数据 在数据加载和过滤完成后，调用此方法
        this.selectFirstRow();

    },
    computed: {
        filteredData() {
            return this.processedTableData.filter(data =>
                !this.searchVal ||
                data.deviceImei.toLowerCase().includes(this.searchVal.toLowerCase()) ||
                (data.index != null && data.index.toString().includes(this.searchVal))
            );
        },
    },
    methods: {
        trackInfor(){
            // 轨迹信息 证书
            //缓存单前选中的轨迹数据到sessionStorage
            window.sessionStorage.setItem('selectPigeonTrackData', JSON.stringify([this.pigeonTableData]));
            
            console.log("轨迹信息", [this.pigeonTableData]);
            // 跳转轨迹证书页面
             // 跳转页面 track
             this.$router.push({
                path: '/track',
            });
        },
        handleEdit(val) {
            console.log("编辑数据", val);
            // 跳转页面 track
            this.$router.push({
                path: '/track',
            });
        },
        // 关闭轨迹数据显示抽屉面板时执行的方法
        handleDrawerClose() {
            this.isShowTrackDataButton = true;
        },

        // TODO 根据设备号查找鸽子轨迹数据展示数据到大屏
        findDevicePigeonData(deviceImei) {

            // 当期设备  deviceImei
            this.currentSelectDevice = deviceImei;

            this.pigeonTableData = this.tableData.find((logGroup) =>
                logGroup.some((log) => log.deviceImei === deviceImei)
            );



            if (this.pigeonTableData) {
                const length = this.pigeonTableData.length;
                // console.log('找到设备'+this.pigeonTableData[0].deviceImei);
                this.currentSelectDeviceStartTime = this.pigeonTableData[0].gpsTime;
                this.currentSelectDeviceEndTime = this.pigeonTableData[length - 1].gpsTime;
                this.currentSelectDeviceFlightduration = this.formatDuration(this.pigeonTableData[length - 1].accumulateDuration);

                let totalDistance = 0; // 累计里程
                let totalDuration = 0;  // 累计时长
                let maxDistance = 0;


                // 平均分速
                // averageSpeed: 0, // 平均速度
                this.pigeonTableData.forEach(log => {
                    // 累计里程和时长（这里假设每个日志都是一个新的行程开始和结束）
                    // 注意：这里的计算方式可能并不准确，因为缺少每个行程的开始时间和里程
                    totalDistance += log.accumulateOdometer; // 这可能是从起点到这里的累计里程
                    totalDuration += log.accumulateDuration; // 这可能是从起点到这里的累计时长（单位可能是秒）


                });
                this.averageSpeed = (totalDistance / totalDuration * 60).toFixed(2);
                // // 最高分速
                // maxSpeed: 0, // 最高速度
                // 优化 数据可能为空
                this.maxSpeed = Math.max(...this.pigeonTableData.map(log => {
                    const odometer = log.accumulateOdometer || 0; // 如果为空或未定义，则默认为0
                    const duration = log.accumulateDuration || 1; // 防止除以零，如果未定义则默认为1（或选择其他合适的值）
                    const speed = (odometer > 0 && duration > 0) ? ((odometer / duration) * 60).toFixed(2) : '0.00';
                    return parseFloat(speed); // 返回浮点数用于Math.max计算，因为toFixed返回的是字符串
                }));

                // averageAltitude: 0, // 平均海拔
                // // 最大高度
                // maxAltitude: 0, // 最大高度
                // // 实际距离
                // actualDistance: 0, // 实际距离
                let total = 0;
                let totalTime = 0;
                for (let i = 1; i < this.pigeonTableData.length; i++) {
                    const currentOdometer = this.pigeonTableData[i].accumulateOdometer;
                    const previousOdometer = this.pigeonTableData[i - 1].accumulateOdometer;
                    // 总距离 = 后一个 - 上一个
                    total += currentOdometer - previousOdometer;

                    // 总时长
                    const currentDuration = this.pigeonTableData[i].accumulateDuration;
                    const previousDuration = this.pigeonTableData[i - 1].accumulateDuration;
                    totalTime += currentDuration - previousDuration;
                }

                this.actualDistance = (total / 1000).toFixed(2);
                // // 实际分速
                // actualSpeed: 0, // 实际速度 
                this.actualSpeed = (total / totalTime * 60).toFixed(2);

                // 直线距离 起点经纬度 到 终点 经纬度 距离
                // lineDistance: 0, // 直线距离
                let startPosition = Cesium.Cartesian3.fromDegrees(this.pigeonTableData[0].lng, this.pigeonTableData[0].lat);
                let endPosition = Cesium.Cartesian3.fromDegrees(this.pigeonTableData[length - 1].lng, this.pigeonTableData[length - 1].lat);
                let resultDistance = Cesium.Cartesian3.distance(startPosition, endPosition)
                this.lineDistance = (resultDistance / 1000).toFixed(2);
                // // 直线分速
                // lineSpeed: 0, // 直线速度
                this.lineSpeed = (resultDistance / totalTime * 60).toFixed(2);
                // 平均海拔高度
                const totalAltitude = this.pigeonTableData.reduce((sum, log) => sum + log.altitude, 0);
                this.averageAltitude = (totalAltitude / length).toFixed(2);
                // 最大高度
                this.maxAltitude = Math.max(...this.pigeonTableData.map(log => log.altitude));



            } else {
                // 没有找到设备 清空之前的数据
                // this.pigeonTableData = [];
                this.currentSelectDeviceStartTime = '';
                this.currentSelectDeviceEndTime = '';
                this.currentSelectDeviceFlightduration = '';

                this.averageSpeed = 0;
                this.maxSpeed = 0;
                this.averageAltitude = 0;
                this.maxAltitude = 0;
                this.actualDistance = 0;
                this.actualSpeed = 0;
                this.lineDistance = 0;
                this.lineSpeed = 0;
                // averageAltitude: 0, // 平均海拔
                // // 最大高度
                // maxAltitude: 0, // 最大高度
                // // 实际距离
                // actualDistance: 0, // 实际距离
                // // 实际分速
                // actualSpeed: 0, // 实际速度
                // // 直线距离
                // lineDistance: 0, // 直线距离
                // // 直线分速
                // lineSpeed: 0, // 直线速度
            }

        },

        // TODO 轨迹数据
        // TODO 计算速度 = (当前位置-上一个位置) / (当前时间 - 上一个时间);
        calculateSpeed(odometer, duration) {
            // 确保 odometer 不为空，以避免除以0的错误
            if (!odometer || odometer === 0) {
                return 0;
            }
            // 确保duration不是0，以避免除以0的错误
            return duration === 0 ? 0 : (odometer / duration * 60).toFixed(2); // 保留两位小数
        },
        // 格式化GPS时间
        formatGpsTime(gpsTime) {
            // "2023-11-08 08:30:00"
            const [date, time] = gpsTime.split(' ');
            return time;
        },
        // 格式累积时间
        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            seconds %= 3600;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
        },
        padZero(number) {
            return number.toString().padStart(2, '0');
        },

        // 计算距离 起点 和 当前位置的距离
        calculateDistance(nowLng, nowEnd) {
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(nowLng, nowEnd);
            // 计算距离 m 换成km 保留2位小数
            // return  Cesium.Cartesian3.distance(startPosition,endPosition).toFixed(2);
            return (Cesium.Cartesian3.distance(startPosition, endPosition) / 1000).toFixed(2);
        },
        // 表格轨迹数结束

        // TODO 点击表格数据
        handleCurrentChange(val) {
            console.log("点击表格数据当前行", val);
            // 显示数据
            this.findDevicePigeonData(val.deviceImei);

            this.currentRow = val;
            if (this.markerMapMode) { // 表示没有关闭显示的定位点
                // 隐藏弹窗
                if (this.popup) {
                    this.popup.removeFrom(window.viewer2DP); // 假设你在Vue实例上挂载了Cesium Viewer
                    this.popup = null;
                }

                let imei = val.deviceImei;

                //记住选中的设备号
                window.sessionStorage.setItem('historicalTrackDeviceImei', imei);

                console.log("点击了某一个", val, imei);
                // this.seleUserId = row.userId;
                // 先隐藏所有标记点
                this.hideAllMarkerEntitys();
                // 显示当前点击的标记点
                this.showMarkerEntity(imei);
                // 重置所有线条宽度
                this.resetPolylineWidth();
                // 改变线条大小
                this.setPolylineWidth(imei, 6);
            }

            // 显示轨迹数据 用触发的方式显示
            this.$nextTick(() => {
                this.isShowTrack();
            });


        },
        goGoogleMap() {
            this.$router.push('/gmap2D')
        },
        toggleMapMode() {
            // 改成跳转页面
            this.$router.push('/pigeonRacingPlatform3D')
        },
        ShowbaseLayerPicker() {
            this.isShowbaseLayerPicker = !this.isShowbaseLayerPicker;
        },
        showMarkerMapMode() {
            // 显示地图标记点
            this.markerMapMode = !this.markerMapMode;
            // console.log("cesium 3d",window.viewer2DP.scene.mode);
            if (this.markerMapMode) {
                // this.hideAllMarkerEntitys(); 
                console.log("cesium 显示标记点");
                // 从缓存中获取设备号，然后显示  window.sessionStorage.setItem('historicalTrackDeviceImei', imei);
                let imei = window.sessionStorage.getItem('historicalTrackDeviceImei');
                this.showMarkerEntity(imei);
            } else {
                this.hideAllMarkerEntitys();

            }
            // // 校验cesium 地图是不是3d的
            // if(window.viewer2DP.scene.mode === Cesium.SceneMode.SCENE3D){
            //     console.log("cesium 3d");
            // }else{
            //     console.log("cesium 2d");
            // }
        },
        // TODO 是否全屏显示
        isFullScreen() {
            this.isShowFullscreenButton = !this.isShowFullscreenButton;
            // console.log("isFullScreen", Cesium.Fullscreen.fullscreen);
            if (Cesium.Fullscreen.fullscreen) { // true 退出全屏 false 全屏
                Cesium.Fullscreen.exitFullscreen()
                // Cesium.Fullscreen.requestFullscreen(window.viewer2DP.scene.canvas)

            } else {
                Cesium.Fullscreen.requestFullscreen(document.body)
                // Cesium.FullScreen.destroy();
                // Cesium.Fullscreen.exitFullscreen ()
            }
        },

        isShowTrack() { // 显示  轨迹数据

            // 显示轨迹数据按钮切换状态
            this.isShowTrackDataButton = !this.isShowTrackDataButton;

            // 显示数据
            this.showTrackTable = true;
        },
        // TODO 样式
        showBox() {
            this.isBoxVisible = true;
            this.isShowIoc = true;
            // 可选：调整botBox的位置以正好显示在“数据”上方
            this.$nextTick(() => {
                const botBox = this.$refs.botBox;
                // 这里可以根据需要添加逻辑来调整botBox的样式，比如top和left属性
                // 注意：由于botBox使用了绝对定位，你可能需要为其父元素设置相对定位（position: relative;）
            });
        },
        hideBox() {
            this.isBoxVisible = false;
            this.isShowIoc = false;
        },
        showEchelonPopup() {
            // 判断赛鸽排名窗口是否展示 展示就要关闭
            if (this.isShowRanking) {
                this.isShowRanking = false;
            }
            // 显示阶梯数据
            this.isEchelonVisible = true;
            // console.log("echelonPopup")
        },
        // 显示赛鸽排名
        showPigeonRankingPopup() {
            // 判断阶梯数据窗口是否展示 展示就要关闭
            if (this.isEchelonVisible) {
                this.isEchelonVisible = false;
            }
            // 显示赛鸽排名(
            this.isShowRanking = !this.isShowRanking;
        },
        // 关闭阶梯数据
        closeEchelon() {
            this.isEchelonVisible = false;
        },
        // 关闭赛鸽排名
        closeRanking() {
            this.isShowRanking = false;
        },
        //  结束样式
        // TODO 获取假数据方法
        processTableData() {
            let idCounter = 1; // 初始化ID计数器

            // console.log("distance", this.startLng, this.startLat)

            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);

            // // TODO 计算 起点和终点的距离
            // // 计算距离 保留2位小数
            let distance = (Cesium.Cartesian3.distance(startPosition, endPosition) / 1000).toFixed(2);
            // console.log("distance",distance)
            // let endDistance = 10;
            this.processedTableData = this.tableData.map(array => {
                if (array.length > 0) {
                    return {
                        index: idCounter++,
                        deviceImei: array[0].deviceImei,
                        deviceName: array[0].deviceName,
                        endDistance: distance
                    };
                }
                return null; // 如果数组为空，返回 null 或处理为空对象等
            }).filter(item => item !== null); // 过滤掉 null 值
        },
        // TODO 选中某个设备号 显示轨迹线
        selectFirstRow() {
            // 确保有数据可供选择
            if (this.filteredData.length > 0) {
                //到缓存中 找设备号
                let imei = window.sessionStorage.getItem('myDeviceImei');
                if (imei) {
                    // 缓存中有设备号 直接显示轨迹线
                    let data = { deviceImei: imei };
                    this.handleCurrentChange(data)
                } else {
                    // 由于无法直接触发 el-table 的行点击事件，
                    // 我们直接调用 handleCurrentChange 方法并传入第一条数据
                    const firstRow = this.filteredData[0];
                    this.$nextTick(() => {
                        this.handleCurrentChange(firstRow);
                        // 手动触发行点击事件
                        // this.$refs.table.setCurrentRow(firstRow);
                    });
                }

            }
        },


        // 绘制轨迹线
        drawPolyline(coordinates) {
            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < coordinates.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < coordinates[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lon, lat] = coordinates[i][j];
                    positions.push(Cesium.Cartesian3.fromDegrees(coordinates[i][j].lng, coordinates[i][j].lat));
                }
                // 使用positions数组创建polyline实体，并添加到viewer中
                window.viewer2DP.entities.add({
                    polyline: {
                        positions: positions, // 使用转换后的Cartesian3对象数组
                        width: 2,
                        material: Cesium.Color.fromCssColorString(this.getRandomColor()),
                    }
                });
            }

            //           // 绘制线
            // var dataSource = new Cesium.CustomDataSource("linedata");
            //             window.viewer2DP.dataSources.add(dataSource);
            //             dataSource.entities.add({
            //                 polyline: {
            //                     name: "line",

            //                     positions: positions,
            //                     width: 5,
            //                     material: Cesium.Color.fromCssColorString("#00ff00"),

            //                 }
            //             });

            //     window.viewer2DP.zoomTo(dataSource)
        },
        // TODO 初始化地图
        initCesiumMap() {
            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 备份自己的  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            // 詹总  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzUwYjE0NS04ZTQ0LTRkMWMtYTI4Yi1iOWFhNDMzNDY3ZTAiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzE4OTg0OTR9.mj5LfUew2Hw2PyB9Fctyv4_KzPXv4GnyvHDlTqaNODQ

            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            const lang = window.sessionStorage.getItem('lang');
            if (lang !== 'en-US') { //  中文
                window.viewer2DP = new Cesium.Viewer("cesiumContainer", {
                    // 优化

                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, //  无操作时自动渲染帧率，设为数字会消耗性能，Infinity为无操作不渲染


                    homeButton: true,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: false, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),


                    // useBrowserRecommendedResolution: false, // 是否选择浏览器推荐分辨率
                    // scene3DOnly: true, // 仅显示三维场景
                    showRenderLoopErrors: false, // 是否显示渲染错误


                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: false  //
                    }),

                    // 影像 imageryProvider
                    // imageryProvider: new Cesium.SingleTileImageryProvider({
                    //     url: require("../../assets/images/map/1.jpg"),
                    // }),



                });
            } else {
                window.viewer2DP = new Cesium.Viewer("cesiumContainer", {
                    // 优化

                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, // 最大渲染时间


                    homeButton: true,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: false, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),

                    showRenderLoopErrors: false, // 是否显示渲染错误
                    // useBrowserRecommendedResolution: false, // 是否选择浏览器推荐分辨率


                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: false  //
                    }),

                    // 英文标注
                    baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),



                });
            }

            window.viewer2DP.scene.mode = Cesium.SceneMode.SCENE2D;



            //  TODO 添加模型
            // window.viewer2DP.dataSources.add(Cesium.GeoJsonDataSource.load(this.markerData, {
            // stroke: Cesium.Color.HOTPINK,
            // fill: Cesium.Color.PINK,
            // strokeWidth: 3,
            // markerSymbol: '?'
            // }));

            // 显示地图阴影
            window.viewer2DP.shadows = false;
            window.viewer2DP.scene.sun.show = false;
            window.viewer2DP.scene.moon.show = false; // 关闭日月

            // window.viewer2DP.scene.globe.enableLighting = true;
            // 显示帧率组件
            window.viewer2DP.scene.debugShowFramesPerSecond = true;

            // 解决浏览器缩放后场景变形模糊的问题 useBrowserRecommendedResolution如果为true，
            // 则以浏览器建议的分辨率进行渲染，并忽略 window.devicePixelRatio（浏览器缩放比例）
            // window.viewer2DP.useBrowserRecommendedResolution = true;

            // 优化2
            // if(Cesium.FeatureDetection.supportsImageRenderingPixelated()){//判断是否支持图像渲染像素化处理  window.devicePixelRatio
            //     window.viewer2DP.resolutionScale =  1.8;
            //     }

            //是否开启抗锯齿
            window.viewer2DP.scene.fxaa = true;
            window.viewer2DP.scene.postProcessStages.fxaa.enabled = false;





            // window.viewer2DP.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // 解决浏览器缩放后场景变形模糊的问题 useBrowserRecommendedResolution如果为true，
            // 则以浏览器建议的分辨率进行渲染，并忽略 window.devicePixelRatio（浏览器缩放比例）
            // window.viewer2DP.useBrowserRecommendedResolution = true;

            // 校验如果是电脑端 开启抗锯齿
            const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

            // 简单的User Agent检查示例
            if (/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
                // 这是移动设备（手机或平板）
                // this.device = /ipad|tablet/i.test(userAgent.toLowerCase()) ? 'tablet' : 'phone';
                console.log('移动端', userAgent);
                // window.alert(userAgent)
                window.viewer2DP._cesiumWidget._supportsImageRenderingPixelated = Cesium.FeatureDetection.supportsImageRenderingPixelated();
                window.viewer2DP._cesiumWidget._forceResize = true;
                if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
                    var vtxf_dpr = window.devicePixelRatio;
                    // 适度降低分辨率
                    while (vtxf_dpr >= 2.0) {
                        vtxf_dpr /= 2.0;
                    }
                    //alert(dpr);
                    window.viewer2DP.resolutionScale = vtxf_dpr;
                }
                window.viewer2DP.scene.globe.maximumScreenSpaceError = 4 / 3;

            } else {
                // 这是桌面设备
                console.log('桌面端', userAgent);
                // this.device = 'desktop';
                window.viewer2DP.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿

                // 2 js 性能优化 降低屏幕空间误差，提高渲染效率
                window.viewer2DP.scene.globe.maximumScreenSpaceError = 1.5;
                window.viewer2DP.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
                window.viewer2DP.resolutionScale = window.devicePixelRatio; // 分辨率
            }


            //  TODO 优化性能
            // window.viewer2DP.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer2DP.scene.debugShowFramesPerSecond = true;
            // window.viewer2DP.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer2DP.scene.msaaSamples = 4 // 抗锯齿
            // window.viewer2DP.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            // window.viewer2DP.highDynamicRangeSupported && (window.viewer2DP.scene.highDynamicRange = true); // 开启高动态范围渲染
            // // viewer.resolutionScale = window.devicePixelRatio; // 分辨率

            // Hides the stars
            // window.viewer2DP.scene.skyBox.show = false;
            // Explicitly render a new frame
            // window.viewer2DP.scene.requestRender(); // 渲染

            // 如果缓存中没有数据 就不显示地图
            // TODO 测试获取轨迹数据
            const pigeonData = window.sessionStorage.getItem('historicalTrackEndLon') || '';
            if (!pigeonData) return; // 没有数据 不显示地图

            // 绘制起点终点
            // TODO 绘制贴地线   lng: 116.405419,
            // lat: 39.918034,   lng: 118.497402,
            // lat: 38.344641,
            // startPosition :0,
            // endPosition :0,
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
            this.createGroundLineAndBillboards(startPosition, endPosition);



            // TODO 调用模型动画 
            // ./mapGLB/gezia.glb

            // this.modelAnimate(this.list,'/mapGLB/pigeon1.glb');
            // this.modelAnimate(this.list, './mapGLB/Cesium_Air.glb');

            this.modelAnimate(this.list, './mapGLB/pigeon_qujiao.glb');





            //TODO 按钮 home 500000
            window.viewer2DP.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                // 你要飞的位置
                // window.viewer2DP.camera.flyTo({
                //     destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 100000.0),
                //     duration: 1, // 以秒为单位的飞行持续时间。
                //     offset: {
                //         heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                //         pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                //         range: 10								// 到中心的距离，以米为单位。								
                //     }
                // });


                let startPositi = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 200000.0);
                let endPositi = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat, 200000.0);
                // 计算中点
                let midpoint = Cesium.Cartesian3.midpoint(startPositi, endPositi, new Cesium.Cartesian3());


                window.viewer2DP.camera.flyTo({
                    destination: midpoint,
                    duration: 2, // 以秒为单位的飞行持续时间。
                    offset: {
                        heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                        pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                        range: 10								// 到中心的距离，以米为单位。								
                    }
                });

            });


            // 加载OSM在线地图（标准风格）
            // window.viewer2DP.imageryLayers.addImageryProvider(
            //     new Cesium.UrlTemplateImageryProvider({
            //         url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            //         subdomains: ["a", "b", "c", "d"],
            //     })
            // );

            // 添加天地图影像注记底图 注册开发者，设置域名白名单 
            // window.viewer2DP.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
            //     url: "http://t0.tianditu.gov.cn/cia_w/wmts?tk=f180b8af3efcf798a9281dd0f0d3dcae",
            //     layer: "cia",
            //     style: "default",
            //     tileMatrixSetID: "w",
            //     format: "tiles",
            //     maximumLevel: 18
            // }))

            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            if (lang !== 'en-US') {
                // 如果需要叠加高德/百度注记地图则添加以下代码
                window.viewer2DP.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                    url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    layer: "tdtAnnoLayer",
                    style: "default",
                    format: "image/jpeg",
                    tileMatrixSetID: "GoogleMapsCompatible"
                }))
            }

            // 自定义的近地天空盒 近景天空
            let groundSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/lantian/Left.jpg'),
                    positiveX: require('@/assets/skyBox/lantian/Right.jpg'),
                    negativeY: require('@/assets/skyBox/lantian/Back.jpg'),
                    positiveY: require('@/assets/skyBox/lantian/Front.jpg'),
                    negativeZ: require('@/assets/skyBox/lantian/Down.jpg'),
                    positiveZ: require('@/assets/skyBox/lantian/Up.jpg')
                }
            })

            // 自带的默认天空盒
            // let defaultSkybox = window.viewer2DP.scene.skyBox;
            // 自定义的近地天空盒 远处 天空
            let defaultSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/5/tycho2t3_80_mx.jpg'),
                    positiveX: require('@/assets/skyBox/5/tycho2t3_80_px.jpg'),
                    negativeY: require('@/assets/skyBox/5/tycho2t3_80_my.jpg'),
                    positiveY: require('@/assets/skyBox/5/tycho2t3_80_py.jpg'),
                    negativeZ: require('@/assets/skyBox/5/tycho2t3_80_mz.jpg'),
                    positiveZ: require('@/assets/skyBox/5/tycho2t3_80_pz.jpg')
                }
            })
            // 渲染前监听并判断相机位置
            window.viewer2DP.scene.preUpdate.addEventListener(() => {
                let position = window.viewer2DP.scene.camera.position;
                let cameraHeight = Cesium.Cartographic.fromCartesian(position).height;
                if (cameraHeight < 240000) {
                    window.viewer2DP.scene.skyBox = groundSkybox;
                    window.viewer2DP.scene.skyAtmosphere.show = false;
                } else {
                    window.viewer2DP.scene.skyBox = defaultSkybox;
                    window.viewer2DP.scene.skyAtmosphere.show = true;
                }
            })


            // 计算方向向量 startPosition  endPosition
            const direction = Cesium.Cartesian3.subtract(endPosition, startPosition, new Cesium.Cartesian3());
            const normalizedDirection = Cesium.Cartesian3.normalize(direction, new Cesium.Cartesian3());
            const face = Math.atan2(normalizedDirection.y, normalizedDirection.x);


            // TODO 绘制实体圆圈
            this.createCircleEntity(face, endPosition, this.circleRadius_30, 'enclosure_30', "#ff0000");
            this.createCircleEntity(face, endPosition, this.circleRadius_100, 'enclosure_100', "#0000ff");
            this.createCircleEntity(face, endPosition, this.circleRadius_200, 'enclosure_200', "#00ff00");
            this.createCircleEntity(face, endPosition, this.circleRadius_300, 'enclosure_300', "#ff00ff");
            this.createCircleEntity(face, endPosition, this.circleRadius_400, 'enclosure_400', "#ffff00");

            // 通过 ID 获取实体
            const dynamicEntity_30 = window.viewer2DP.entities.getById("enclosure_30");
            const dynamicEntity_100 = window.viewer2DP.entities.getById("enclosure_100");
            const dynamicEntity_200 = window.viewer2DP.entities.getById("enclosure_200");
            const dynamicEntity_300 = window.viewer2DP.entities.getById("enclosure_300");
            const dynamicEntity_400 = window.viewer2DP.entities.getById("enclosure_400");

            // console.log("绘制一个中心透明获取的实体position", dynamicEntity.position.getValue());

            this.circleCenter_30 = dynamicEntity_30.position.getValue();
            this.circleCenter_100 = dynamicEntity_100.position.getValue();
            this.circleCenter_200 = dynamicEntity_200.position.getValue();
            this.circleCenter_300 = dynamicEntity_300.position.getValue();
            this.circleCenter_400 = dynamicEntity_400.position.getValue();

            //TODO 调用绘制广告牌
            //  this.createIntersectionBillboards(this.startLng, this.startLat,this.endLng,  this.endLat,this.endLng,  this.endLat,circleRadius_30);



            // TODO 弹窗 888 点击事件 

            var handler = new Cesium.ScreenSpaceEventHandler(window.viewer2DP.scene.canvas);
            handler.setInputAction((click) => {
                //         this.showMarkerEntity("867585332231317");
                // console.log('左键单击事件：', click.position);

                // 点击实体触发事件
                const pickedObject = window.viewer2DP.scene.pick(click.position);
                // console.log('pickedObject', pickedObject.id);
                // console.log('pickedObject', pickedObject.userData);
                if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
                    // 通过id 找实体
                    var entitypickedObject = pickedObject.id;
                    const imei = entitypickedObject.id.split('polyline_')[1]; // 假设id格式为'polyline_IMEI'
                    // console.log('过id 找实体imei', imei);
                    if (imei) {
                        // 找到了实体
                        let data = { deviceImei: imei };
                        this.handleCurrentChange(data)

                    } else {

                        const entity = window.viewer2DP.entities.getById(pickedObject.id._id)
                        // 找到了实体
                        if (entity.userData) {
                            // console.log("点击的实体id-》entity", entity);
                            //      console.log('Description:', entity.userData);


                            // 将对象转换为数组
                            // var positionArray = [position.x, position.y, position.z];
                            let lng = entity.userData.lng;
                            let lat = entity.userData.lat;
                            let height = entity.userData.height;
                            let distance = entity.userData.distance;
                            let tiem = entity.userData.time;
                            let speed = entity.userData.speed;
                            let ranking = entity.userData.ranking;
                            let deviceVol = entity.userData.deviceVol;
                            let myposition = [lng, lat, 0];
                            let pointIndex = entity.userData.pointIndex;
                            //                             2024-26-0187343
                            // 時間：2024-11-30 11:48:48
                            // 速度：1020m/min
                            // 方向：西
                            // 海拔：-17m
                            // 名次：2
                            // <li>经度：${lng}</li>
                            // <li>纬度：${lat}</li>
                            //  <button id="btn">按钮</button>
                            const htmlq = `
                                    <ul style="width: 100%; height: 100%;font-size:18px; color:white;padding-top:6px;">
                                          <li>${this.$t('phoneLanguage.time')}:${tiem}</li>
                                            <li>${this.$t('phoneLanguage.speed')}:${speed}（m/min）</li>

                                    <li>${this.$t('phoneLanguage.altitude')}:${height}（m）</li>
                                    <li>${this.$t('phoneLanguage.distanceToDestination')}:${distance}（km）</li>
                                    <li>${this.$t('phoneLanguage.deviceVol')}:${deviceVol}（%）</li>
                                     <li>${this.$t('phoneLanguage.ranking')}:${ranking}</li>
                                    <li>${this.$t('phoneLanguage.pointIndex')}:${pointIndex}</li>
                                    </ul>
                                `;

                            // 如果popup已存在，则先移除它
                            // console.log("如果popup已存在，则先移除它", this.popup);
                            if (this.popup) {
                                this.popup.removeFrom(window.viewer2DP); // 假设你在Vue实例上挂载了Cesium Viewer
                                this.popup = null;
                            }


                            // 创建新的弹窗
                            // 注意：这里需要确保CustomPopup是可用的，并且它的构造函数接受正确的参数
                            this.popup = new CustomPopup(
                                myposition,
                                htmlq,
                                "interaction",
                                {
                                    title: (pickedObject.id._id).split('marker_')[1],
                                    popupAt: "center", lineHeight: 100
                                }
                            );

                            // 将新弹窗添加到Cesium的Viewer中
                            this.popup.addTo(window.viewer2DP);

                            // 设置弹窗关闭时的事件处理器
                            this.popup.onClosePopup(() => {
                                // console.log("关闭了弹窗");
                                this.popup = null; // 弹窗关闭后，将popup设置为null
                            });
                            // this.popup.onClickPopup((e) => {
                            // if (e.target.id === "btn") {
                            //     alert("点击了p1-按钮");
                            // }
                            // });



                        }
                    }





                }


                //     if (position) {
                // console.log('位置信息position：', positionArray);
                // console.log('位置信息：', Cesium.Cartographic.fromCartesian(position));
                //         // var cartesian2= Cesium.SceneTransforms.wgs84ToWindowCoordinates(viewer.scene,cartesian3)
                //         var cartographicPositions = Cesium.Ellipsoid.WGS84.cartesianArrayToCartographicArray(Cesium.Cartographic.fromCartesian(position));
                //         console.log('WGS848经纬度信息：x , y , z ',cartographicPositions);
                //         console.log('WGS848经纬度信息：x , y , z ', new Cesium.Cartesian3(-2967602.291477882, 5042542.63032606, 2530832.860173073 ));


                //     }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);






            // 确保时间轴更新
            // window.viewer2DP.timeline.updateFromClock();
            // window.viewer2DP.timeline.zoomTo(window.viewer2DP.clock.startTime, window.viewer2DP.clock.stopTime);

            // // 最小缩放高度（米）
            // window.viewer2DP.scene.screenSpaceCameraController.minimumZoomDistance = 500;
            // // 最大缩放高度（米）
            // window.viewer2DP.scene.screenSpaceCameraController.maximumZoomDistance = 500000;

            // 鼠标滚轮控制视角
            // window.viewer2DP.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];
            // window.viewer2DP.scene.screenSpaceCameraController.tiltEventTypes = [ Cesium.CameraEventType.LEFT_DRAG ];

            // window.viewer2DP.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];


        },

        // TODO 封装方法 绘制贴地线
        createGroundLineAndBillboards(startPosition, endPosition) {
            // 创建贴地线
            var instance = new Cesium.GeometryInstance({
                geometry: new Cesium.GroundPolylineGeometry({
                    positions: [
                        startPosition,
                        endPosition
                    ],
                    width: 1.0
                }),
                id: 'object returned when this instance is picked and to get/set per-instance attributes'
            });

            window.viewer2DP.scene.groundPrimitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instance,
                appearance: new Cesium.PolylineMaterialAppearance({
                    material: Cesium.Material.fromType('#FF00ff')
                })
            }));

            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            // window.viewer2DP.camera.flyTo({
            //     //   lon: 116.405419,
            //     // lat: 39.918034,
            //     // fromDegrees()方法，将经纬度和高程转换为世界坐标，这里定位到中国  101.8, 33.74,  121.795693, 25.146142
            //     //                     heading-代表镜头左右方向,正值为右,负值为左,360度和0度是一样的
            //     // pitch-代表镜头上下方向,正值为上,负值为下.
            //     // roll-代表镜头左右倾斜.正值,向右倾斜,负值向左倾斜、
            //     // Cesium.Cartesian3.fromDegreesArrayHeights( [this.startLng, this.startLat,5000,this.endLng,  this.endLat, 5000]),
            //   let startPosition = Cesium.Cartesian3.fromDegrees( this.startLng, this.startLat);
            // let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng,  this.endLat);

            //     destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 100000),
            //     // orientation: {
            //     //     // 指向 z 180
            //     //     // heading:Cesium.Math.toRadians(-10),
            //     //     // 视角 y
            //     //     pitch:Cesium.Math.toRadians(-90),
            //     //     // 视角 x
            //     //     roll: -3.0,
            //     // },
            //     duration: 2,	// 以秒为单位的飞行持续时间。
            //     offset: {
            //         heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //         pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //         range: 10								// 到中心的距离，以米为单位。								
            //     }
            // });


            let startPositi = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 130000.0);
            let endPositi = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat, 130000.0);
            // 计算中点
            let midpoint = Cesium.Cartesian3.midpoint(startPositi, endPositi, new Cesium.Cartesian3());


            window.viewer2DP.camera.flyTo({
                destination: midpoint,
                duration: 2, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });






            // 在起点添加广告牌
            window.viewer2DP.entities.add({
                position: startPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // alignedAxis: Cesium.Cartesian3.UNIT_Z, // 设置广告牌始终面向相机
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                model: {
                    uri: './mapGLB/start2.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.BOTTOM, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画


                },
                label: {
                    text: this.$t('phoneLanguage.startPoint'),
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小  font: '24px Helvetica',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Material.fromType('#00ff00'),
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -40), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });


            // // 在终点添加广告牌
            window.viewer2DP.entities.add({
                position: endPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                model: {
                    uri: './mapGLB/end3.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画

                },
                label: {
                    text: this.$t('phoneLanguage.endPoint'),

                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.RED,
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, 26), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });



        },
        // TODO 方法 绘制圈
        createCircleEntity(face, position, radius, id, color) {


            // TODO 绘制公里

            const offset = new Cesium.Cartesian3(
                radius * Math.cos(face),
                0,
                radius * Math.sin(face)
            );
            const positionOnCircle = Cesium.Cartesian3.add(position, offset, new Cesium.Cartesian3());
            const km = radius / 1000;
            // 绘制公里文字
            //    // TODO 绘制公里
            window.viewer2DP.entities.add({
                show: true,
                id: id + this.$t('phoneLanguage.kilometer'), // 
                position: positionOnCircle,
                // 广告牌
                label: {
                    text: km + this.$t('phoneLanguage.kilometer'),
                    scale: 0.5,       // 大小
                    font: '32px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.WHITE,
                    outlineColor: Cesium.Color.BLACK,
                    outlineWidth: 2,


                    pixelOffset: new Cesium.Cartesian2(5, -20), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                },
            });





            var circleEntity = window.viewer2DP.entities.add({
                id: id,
                position: position, // 设置圆心位置
                name: id, // 使用ID作为实体名称
                ellipse: {
                    semiMinorAxis: radius, // 设置椭圆的短半轴，与长半轴相等，形成圆
                    semiMajorAxis: radius, // 设置椭圆的长半轴
                    extrudedHeight: 4000, // 设置高度
                    // height: 0, // 设置高度为0，使其贴合地面
                    material: Cesium.Color.fromCssColorString('#ffffff00'),// 设置填充颜色为绿色但完全透明

                    outline: true, // 启用外边框
                    // 可以根据需要设置outlineColor和outlineWidth，或者作为额外参数传入
                    outlineColor: Cesium.Color.fromCssColorString(color), // 默认设置外边框颜色为红色
                    outlineWidth: 2 // 默认设置外边框宽度为2
                }
            });
            return circleEntity; // 返回创建的实体对象，以便后续操作（可选）
        },

        // TODO 方法 隐藏显示鸽子设备
        hideShowDevice(deviceImeiArray) {

            // 获取所有以'point_'开头的鸽子实体
            var pointEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 先把所有的隐藏
            pointEntities.forEach(entity => {
                entity.show = false;
            });


            // 确保传入的数组有效
            if (!Array.isArray(deviceImeiArray) || deviceImeiArray.length === 0) {
                // console.warn('传入的设备号数组无效或为空');
                return;
            }



            // 创建一个映射，用于快速查找实体是否存在于设备号数组中
            const imeiToEntityMap = {};
            pointEntities.forEach(entity => {
                const imei = entity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                imeiToEntityMap[imei] = entity;
            });
            // 
            // 判断是否只有一个设备，则显示设备
            if (deviceImeiArray.length === 1) {
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;
                        // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        window.viewer2DP.trackedEntity = entity;
                        // entity.label.fillColor = Cesium.Color.RED;
                        // console.log('显示设备号: ' + imei);

                        window.viewer2DP.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            } else {

                // 遍历设备号数组，处理每个设备号
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;

                        // // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        // if (entity.label) {

                        //     // entity.label.fillColor = Cesium.Color.RED;
                        //     console.log('显示设备号: ' + imei);
                        // }
                        // window.viewer2DP.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            }

        },
        //TODO 移除，隐藏 所有鸽子实体 
        removeAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer2DP.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer2DP.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });
            // for (var i = 0; i < pointEntities.length; i++) {
            //     window.viewer2DP.entities.remove(pointEntities[i]);
            // }

        },

        // 全选 显示所有实体
        showAllEntity() {
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            pointEntities.forEach(function (entity) {
                entity.show = true;         // 显示所有实体
            });

        },
        // TODO 根据设备号显示他所有的标记点
        showMarkerEntity(deviceImei) {
            // 获取所有以'point_'开头的实体
            var markerEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('marker_' + deviceImei);
            });

            // 如果没有找到任何实体，则退出函数
            if (markerEntities.length === 0) {
                console.log("显示没有找到以'marker_'开头的实体");
                return;
            }

            markerEntities.forEach(entity => {
                // window.viewer2DP.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 隐藏所有实体
            });

        },
        // TODO 根据设备号隐藏他所有的标记点
        hideMarkerEntity(deviceImei) {
            // 获取所有以'point_'开头的实体
            var markerEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('marker_' + deviceImei);
            });

            // 如果没有找到任何实体，则退出函数
            if (markerEntities.length === 0) {
                console.log("隐藏没有找到以'marker_'开头的实体");
                return;
            }

            markerEntities.forEach(entity => {
                // window.viewer2DP.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });

        },

        // TODO 隐藏所有的标记
        hideAllMarkerEntitys() {
            // 获取所有以'point_'开头的实体
            var markerAllEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('marker_');
            });

            // 如果没有找到任何实体，则退出函数
            if (markerAllEntities.length === 0) {
                console.log("隐藏没有找到以'marker_'开头的实体");
                return;
            }
            markerAllEntities.forEach(entity => {
                // window.viewer2DP.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });
        },

        // TODO 根据设备号找轨迹线 改变线条的大小  polyline_
        setPolylineWidth(deviceImei, width) {
            // 获取所有以'polyline_'开头的实体
            var polylineEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_' + deviceImei);
            });

            // 如果没有找到任何实体，则退出函数
            // 如果没有找到任何实体，则退出函数
            if (polylineEntities.length === 0) {
                console.log("隐藏没有找到以'marker_'开头的实体");
                return;
            }
            polylineEntities.forEach(entity => {

                // 改变线条的宽度
                entity.polyline.width = width;

            });


        },
        // TODO 重置线条 恢复原来大小
        resetPolylineWidth() {
            // 获取所有以'polyline_'开头的实体
            let polylineEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            if (polylineEntities.length === 0) {
                console.log("隐藏没有找到以'marker_'开头的实体");
                return;
            }
            polylineEntities.forEach(entity => {

                // 改变线条的宽度
                entity.polyline.width = 3;

            });
        },



        // TODO  优化 不行 绘制广告牌
        // 起点经度纬度，和终点经度纬度，和圆的中心点经度纬度，和圆的半径，找出圆边和线的相交点绘制出广告牌 30公里
        createIntersectionBillboards(startLon, startLat, endLon, endLat, circleLon, circleLat, radiusInKm) {
            // 将经纬度转换为笛卡尔坐标
            function cartographicToCartesian(lon, lat, height = 0) {
                const cartographic = Cesium.Cartographic.fromDegrees(lon, lat, height);
                return Cesium.Ellipsoid.WGS84.cartographicToCartesian(cartographic);
            }

            // 计算线段与圆的相交点
            function lineCircleIntersection(lineStart, lineEnd, circleCenter, radius) {
                const direction = Cesium.Cartesian3.subtract(lineEnd, lineStart, new Cesium.Cartesian3());
                Cesium.Cartesian3.normalize(direction, direction);

                const toCenter = Cesium.Cartesian3.subtract(circleCenter, lineStart, new Cesium.Cartesian3());
                const a = Cesium.Cartesian3.dot(direction, direction);
                const b = 2.0 * Cesium.Cartesian3.dot(direction, toCenter);
                const c = Cesium.Cartesian3.dot(toCenter, toCenter) - radius * radius;

                const discriminant = b * b - 4 * a * c;

                const intersections = [];
                if (discriminant >= 0) {
                    const t1 = (-b - Math.sqrt(discriminant)) / (2.0 * a);
                    const t2 = (-b + Math.sqrt(discriminant)) / (2.0 * a);

                    if (t1 >= 0 && t1 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t1, new Cesium.Cartesian3())));
                    }
                    if (t2 >= 0 && t2 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t2, new Cesium.Cartesian3())));
                    }
                }

                return intersections;
            }

            // // 将笛卡尔坐标转换为经纬度
            // function cartesianToCartographic(cartesian) {
            //     return Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
            // }

            // 转换经纬度
            const startCartesian = cartographicToCartesian(startLon, startLat);
            const endCartesian = cartographicToCartesian(endLon, endLat);
            const circleCartesian = cartographicToCartesian(circleLon, circleLat);

            // 半径转换为米
            const radius = radiusInKm; // * 1000;

            // 计算相交点
            const intersections = lineCircleIntersection(startCartesian, endCartesian, circleCartesian, radius);

            console.log("计算相交点:", intersections);

            // TODO zheli 在相交点处添加广告牌
            intersections.forEach((intersection, index) => {
                const position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(intersection);
                window.viewer2DP.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Cesium.Math.toDegrees(position.longitude), Cesium.Math.toDegrees(position.latitude)),
                    // billboard: {
                    //     image: require('@/assets/your-image.png'), // 使用 Vue 的 require 语法来加载本地图片
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    // },
                    id: `billboard_${index}`,
                    label: {
                        text: '123',
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        // scale : 1.5,       // 大小
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        // fillColor: Cesium.Material.fromType('#00ff00'), 
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // // showBackground: true, // 背景
                        // // backgroundColor: Cesium.Color.BLUE,
                        // pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                    }
                });
            });

        },

        // 随机颜色
        // getRandomColor() {
        //     var str = '#';
        //     //一个十六进制的值的数组
        //     var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
        //     for (var i = 0; i < 6; i++) {
        //         var num = parseInt(Math.random() * 16); //随机产生0-15之间的一个数
        //         str += arr[num]; //产生的每个随机数都是一个索引,根据索引找到数组中对应的值,拼接到一起
        //     }
        //     return str;
        // },
        getRandomColor() {
            var str = '#';
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

            let color;
            do {
                color = '#';
                for (var i = 0; i < 6; i++) {
                    var num = parseInt(Math.random() * 16);
                    color += arr[num];
                }
            } while (this.generatedColors.has(color)); // 检查颜色是否已存在

            // 添加新颜色到集合中
            this.generatedColors.add(color);

            return color;
        },
        generateUniqueColor() {
            // 生成并设置新的唯一颜色
            this.uniqueColor = this.getRandomColor();

        },

        // 添加轨迹
        addLine(vals) {
            // 遍历每个线的坐标点数组
            vals.forEach((val, rank) => {
                let positions = [];
                let markers = []; // 用于存储标记点实体
                let previousPosition = null; // 用于存储上一个点的位置
                let previousTime = null;
                let myColor = this.getRandomColor();
                // 遍历当前线的每个坐标点
                val.forEach((item, index) => {


                    const height = (item?.altitude != null) ? (item.altitude) : 0;
                    // 将经纬度转换为Cesium的Cartesian3坐标
                    const position = Cesium.Cartesian3.fromDegrees(item.lng, item.lat, height);
                    positions.push(position);

                    // let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
                    let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
                    // 时间  "gpsLocationTime": 1699403483000,

                    // // TODO 计算 当前位置和终点的距离
                    // // 计算距离 保留2位小数
                    let distance = (Cesium.Cartesian3.distance(position, endPosition) / 1000).toFixed(2);
                    // 计算速度 m/min 当前位置与上一个点的距离除以时间


                    // 解析GPS定位时间（这里假设gpsLocationTime是一个可以解析为Date对象的字符串）
                    const currentTime = new Date(item.gpsLocationTime);

                    // 如果不是第一个点，则计算速度和距离
                    if (previousPosition && previousTime) {
                        // 计算当前点与上一个点之间的距离
                        const distanceToPrevious = Cesium.Cartesian3.distance(position, previousPosition);

                        // 计算时间差（以毫秒为单位），然后转换为分钟
                        const timeDifferenceMilliseconds = currentTime - previousTime;
                        const timeDifferenceMinutes = timeDifferenceMilliseconds / (1000 * 60);

                        // 计算速度（米/分钟）校验 speed 不是 NaN

                        let speed = (distanceToPrevious / timeDifferenceMinutes).toFixed(2);
                        if (isNaN(speed)) {
                            speed = 0;
                        }
                        // TODO 创建标记点实体
                        markers.push({
                            // 可以为每个标记点添加一个唯一的id或名称，方便后续管理
                            id: `marker_` + item.deviceImei + '_' + index,
                            show: false,
                            position: position,
                            // point: {
                            //     // 标记点大小
                            //     pixelSize: 10,
                            //     // 标记点颜色
                            //     color: Cesium.Color.fromCssColorString(this.getRandomColor()),
                            //     // 高度参考
                            //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                            // },
                            //                     // 图片
                            billboard: {
                                color: Cesium.Color.fromCssColorString(myColor),
                                //mark_gps.png  bike_online.png
                                image: require('@/assets/mark_gps.png'),
                                scale: 0.5,       // 大小
                                // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                                // sizeInMeters: true,  // 大小是否以米为单位
                                horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                                // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                                // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                                // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                                // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                                // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                                // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                                // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                            },
                            // TODO 自定标记点数据
                            //   2024-26-0187343
                            // 時間：2024-11-30 11:48:48
                            // 速度：1020m/min
                            // 方向：西
                            // 海拔：-17m
                            // 名次：2
                            // 速度怎么算？ 1020m/min = 1020/60 = 17.33m/s

                            userData: {
                                description: 'This is a description of my entity.' + index,
                                additionalInfo: 'Here is some additional information.' + index,
                                lng: item.lng,
                                lat: item.lat,
                                height: height,
                                distance: distance,
                                time: item.gpsTime,
                                speed: speed,
                                ranking: rank + 1,
                                deviceVol: item.deviceVol,  // 电量
                                pointIndex:item.pointIndex, // 定位点个数
                                // 你可以添加更多自定义属性
                            },

                        });


                    } else {
                        // TODO 创建标记点实体
                        markers.push({
                            // 可以为每个标记点添加一个唯一的id或名称，方便后续管理
                            id: `marker_` + item.deviceImei + '_' + index,
                            show: false,
                            position: position,
                            // point: {
                            //     // 标记点大小
                            //     pixelSize: 10,
                            //     // 标记点颜色
                            //     color: Cesium.Color.fromCssColorString(this.getRandomColor()),
                            //     // 高度参考
                            //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                            // },
                            //                     // 图片
                            billboard: {
                                color: Cesium.Color.fromCssColorString(myColor),

                                image: require('@/assets/mark_gps.png'),
                                scale: 0.5,       // 大小
                                // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                                // sizeInMeters: true,  // 大小是否以米为单位
                                horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                                // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                                // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                                // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                                // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                                // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                                // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                                // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                            },
                            // TODO 自定标记点数据
                            //   2024-26-0187343
                            // 時間：2024-11-30 11:48:48
                            // 速度：1020m/min
                            // 方向：西
                            // 海拔：-17m
                            // 名次：2
                            // 速度怎么算？ 1020m/min = 1020/60 = 17.33m/s

                            userData: {
                                description: 'This is a description of my entity.' + index,
                                additionalInfo: 'Here is some additional information.' + index,
                                lng: item.lng,
                                lat: item.lat,
                                height: item.altitude,
                                distance: distance, //
                                time: item.gpsTime,
                                speed: 0,
                                ranking: rank + 1,
                                deviceVol: item.deviceVol,  // 电量
                                pointIndex:item.pointIndex, // 定位点个数
                                // 你可以添加更多自定义属性
                            },

                        });
                    }

                    // 更新上一个点的位置和时间
                    previousPosition = position;
                    previousTime = currentTime;
                    // 方向
                    // let direction;


                });

                // console.log("遍历每个线的坐标点数组",val[0]);
                // 在viewer中添加一个新的线实体
                window.viewer2DP.entities.add({
                    id: "polyline_" + val[0].deviceImei,
                    polyline: {
                        positions: positions,

                        // 线宽
                        width: 5,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                        // 路线颜色
                        material: Cesium.Color.fromCssColorString(myColor),
                        // 是否贴地
                        clampToGround: true,
                    },
                });

                // 在viewer中添加标记点实体
                markers.forEach(marker => {
                    window.viewer2DP.entities.add(marker);
                });

            });
        },

        // 位置信息  list 对象 异步等待执行
        // TODO 拼装鸽子数据 该高度
        async computeFlight(lists) {
            var properties = []; // 用于存储每个轨迹的SampledPositionProperty对象

            // 遍历外部数组，每个元素是一个包含轨迹点的内部数组
            for (var i = 0; i < lists.length; i++) {
                var property = new Cesium.SampledPositionProperty(); // 为当前轨迹创建一个新的SampledPositionProperty对象
                var list = lists[i]; // 当前轨迹的点集合

                // 遍历当前轨迹的点集合
                for (var z = 0; z < list.length; z++) {
                    var item = list[z];
                    // TODO 根据json 文件不一  time  和 gpsLocationTime   互相更换
                    // height  和  altitude
                    // "lng": 120.407847,
                    // "lat": 23.408993,
                    var thisTime = Cesium.JulianDate.fromDate(new Date(item.gpsLocationTime));
                    const height = (item?.altitude != null) ? (item.altitude + 1000) : 1000;
                    var position = Cesium.Cartesian3.fromDegrees(
                        item.lng,
                        item.lat,
                        height
                    );

                    // 添加每一个链接点的信息，到达的时间以及坐标位置
                    property.addSample(thisTime, position);
                }
                properties.push(property); // 将当前轨迹的SampledPositionProperty对象添加到数组中
            }
            return properties; // 返回包含所有轨迹SampledPositionProperty对象的数组
        },
        // 位置信息  list 数组
        computeFlightArrays(list) {
            var property = new Cesium.SampledPositionProperty();


            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < list.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < list[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lng, lat,time] = list[i][j][t];
                    var thisTime = Cesium.JulianDate.fromDate(new Date(list[i][j].time));
                    positions.push(Cesium.Cartesian3.fromDegrees(list[i][j].lng, list[i][j].lat, list[i][j].height));
                }
                // 添加每一个链接点的信息，到达的时间以及坐标位置
                property.addSample(thisTime, positions);
            }
            return property;
        },
        // 添加模型动画
        // 异步执行
        // TODO 主方法
        async modelAnimate(list, modelGltf) {
            //添加路线 直播效果不要绘制线条
            //   let color = this.getRandomColor();
            this.addLine(list);



            // console.log("第一只鸽子开始时间："+list[0][0].lng);

            //  TODO 开始时间 ，  应该要自定义开始时间？  eventStartTime: 0, //   开始时间
            //   eventEndTime: 0,  // 结束时间
            //   var date = new Date(list[0][0].time);
            //   var start = Cesium.JulianDate.fromDate(date); //获取第一个点的时间 
            // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
            // this.eventEndTime 




            //   // 设置始时钟始时间
            //   window.viewer2DP.clock.startTime = this.startTime.clone();
            //   // 设置时钟当前时间
            //   window.viewer2DP.clock.currentTime = this.startTime.clone();
            //   // 时间速率，数字越大时间过的越快
            //   window.viewer2DP.clock.multiplier = 1;
            //          调用 位置信息
            // let properties = await this.computeFlight(list); // 注意这里变量名改为properties，因为返回的是数组
            // console.log("properties-->", properties);

            // if (properties.length === 0) {
            //     console.error('No flight paths found.');
            //     return;
            // }

            // 选择第一个轨迹的SampledPositionProperty对象
            // let firstProperty = properties[1];




            // 设置始终停止时间（基于第一个轨迹）
            // var times = firstProperty._property._times;
            // var stop = times[times.length - 1];

            // console.log("设置始终停止时间（基于第一个轨迹） ", times, stop);
            // 结束时间：
            // var stopdate = new Date(this.eventEndTime);
            // var stop = Cesium.JulianDate.fromDate(stopdate); //获取第一个点的时间 

            // window.viewer2DP.clock.stopTime = this.stopTime.clone();

            // // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // // 时间轴（使用第一个轨迹的开始和停止时间）
            // window.viewer2DP.timeline.zoomTo(this.startTime, this.stopTime);

            // // 循环执行，到达终止时间，重新从起点时间开始
            // window.viewer2DP.clock.clockRange = Cesium.ClockRange.LOOP_STOP;

            // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）
            // properties.forEach((property, index) => {
            //     //    console.log("index-->", this.zz);
            //     let planeModel = window.viewer2DP.entities.add({
            //         id: "point_" + this.list[index][0].deviceImei,    // 标识符
            //         // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
            //         availability: new Cesium.TimeIntervalCollection([

            //             new Cesium.TimeInterval({
            //                 start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
            //                 stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
            //             }),
            //         ]),
            //         position: property, // 使用当前轨迹的SampledPositionProperty
            //          // 根据所提供的速度计算模型的朝向
            //          orientation: new Cesium.VelocityOrientationProperty(property),
            //         // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
            //         model: {
            //             uri: modelGltf, // 可以根据index加载不同的模型，例如：modelGltfArray[index]
            //             // scale: 1, // 
            //             minimumPixelSize: 128,  // 最小的模型像素
            //             maximumScale: 2000,
            //             //   runAnimations: false,//是否显示动画
            //             // clampAnimations: true,//是否保持最后一针的动画

            //         },


            //         // 图片
            //         //  billboard: {
            //         //     image: require('@/assets/bike_online.png'),
            //         //     scale : 1.5,       // 大小
            //         //     // sizeInMeters: true,  // 大小是否以米为单位
            //         //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
            //         //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
            //         //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
            //         //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
            //         //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
            //         //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
            //         //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
            //         //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
            //         //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

            //         //     },
            //         // 点
            //         point: {
            //             pixelSize: 10,
            //             color: Cesium.Color.WHITE,
            //             // outlineColor: Cesium.Color.BLUE,
            //             // outlineWidth: 3,
            //             // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
            //             horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
            //             verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向
            //         },

            //         label: {
            //             text: this.getLastSixDigits(this.list[index][0].deviceImei),
            //             scale: 1.5,       // 大小
            //             // 隐藏
            //             show: false,
            //             font: '18px sans-serif',
            //             // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
            //             fillColor: Cesium.Color.WHITE,
            //             // outlineColor: Cesium.Color.BLACK, // 轮廓线
            //             // outlineWidth: 2,    // // 轮廓线宽度
            //             // showBackground: true, // 背景
            //             // backgroundColor: Cesium.Color.BLUE,
            //             translucencyByDistance: new Cesium.NearFarScalar(10000, 1, 8000000, 0), // 近远效果 透明度
            //             pixelOffset: new Cesium.Cartesian2(0, -100), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
            //             eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


            //             // font: '20px Helvetica',
            //             // font: '14px sans-serif',
            //             // fillColor: Cesium.Color.RED, 
            //             // outlineWidth: 2,
            //             // outlineColor: Cesium.Color.BLACK
            //         },
            //         // 线条
            //         // path:{
            //         //     show: true,
            //         //     leadTime : 0, // 显示时间
            //         //     trailTime : 15, // 尾迹时间
            //         //     resolution : 1, // 时间分辨率
            //         //     width : 2, // 宽度
            //         //     material : new Cesium.PolylineGlowMaterialProperty({ // 材质
            //         //         glowPower : 0.2,
            //         //         color : Cesium.Color.RED, 
            //         //     }),

            //         // }
            //         // path:{
            //         //     leadTime: 0,
            //         //     resolution:1,
            //         //     material: new Cesium.PolylineGlowMaterialProperty({
            //         //         color: Cesium.Color.WHITE,
            //         //         glowPower: 0.2,

            //         //     }),
            //         //     width: 10
            //         // }
            //         path: {
            //             leadTime: 0,
            //             resolution: 120, // 时间分辨率
            //             // material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE ),

            //             material: Cesium.Color.fromCssColorString(this.getRandomColor()),
            //             width: 5
            //         }

            //     });


            // });
        },
        // TODO 截取设备号保留后6位字符
        getLastSixDigits(str) {
            // 检查字符串长度是否足够
            if (str.length >= 6) {
                return str.slice(-6); // 从字符串末尾开始截取6个字符
            } else {
                // 如果字符串长度不足6，直接返回整个字符串
                return str;
            }
        },
        //创建模型
        addModel(url, height) {
            window.viewer2DP.entities.removeAll();

            const position = Cesium.Cartesian3.fromDegrees(
                104.03697, 34.43811,
                height
            );
            const heading = Cesium.Math.toRadians(135); // 指向
            const pitch = 0;    // 视角
            const roll = 0;     // 偏移
            const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            const orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

            const entity = window.viewer2DP.entities.add({
                name: url,
                position: position,
                orientation: orientation, // 方向
                model: {
                    uri: url,
                    minimumPixelSize: 328,  // 最小的模型像素
                    maximumScale: 20000,
                    // runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    //裁剪 ok
                    // clippingPlanes : new Cesium.ClippingPlaneCollection({
                    //     planes: [
                    //         new Cesium.ClippingPlane(new Cesium.Cartesian3(0,0,1), 0),

                    //     ],
                    //     edgeWidth: 1,
                    //     edgeColor:  Cesium.Color.RED,
                    //     enabled: true,
                    //     edgeMaterial: new Cesium.PolylineOutlineMaterialProperty({
                    //         color: Cesium.Color.RED,
                    //         outerWidth:1,
                    //         outlineColor: Cesium.Color.BLACK,

                    //     }),
                    // }),

                },
            });

            window.viewer2DP.trackedEntity = entity;
        },
        // cesium时钟时间格式化函数
        CesiumTimeFormatter(datetime, viewModel) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")} : ${second.padStart(2, "0")}`;
        },

        // cesium时钟日期格式化函数
        CesiumDateFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            return `${gregorianDT.year}年${gregorianDT.month}月${gregorianDT.day}日`;
        },

        // cesium时间轴格式化函数 年月日时分
        CesiumDateTimeFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            return `${gregorianDT.year}年${gregorianDT.month}月${gregorianDT.day}日${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
        },

        // cesium时间轴格式化函数 年月日时分秒
        CesiumDateTimeXFormatter(datetime, viewModel, ignoredate) {

            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;
        },
        // 按钮点击事件
        switchAnimation() { // TODO 切换实体
            // 获取所有鸽子
            //    var entities = window.viewer2DP.entities.values;
            //         // console.log("---",currentTime);
            //         entities.forEach(function(entity) {
            //             // 只处理以'point_'开头的实体，假设这些是您想要监控的点
            //             if (entity.id.startsWith('point_')) {

            //                 var position = entity.position.getValue();
            //                 if(position)
            //                 console.log("切换模型---",position);
            //             }

            //         });

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;
            // 获取当前选中的实体
            var targetEntity = pointEntities[this.currentIndex];
            // 拿到设备号
            const deviceImei = (targetEntity.id).split('point_');
            // 显示该设备号的标记点
            this.showMarkerEntity(deviceImei[1]);
            //  console.log("切换模型---",(targetEntity.id).split('point_'));

            // 如果存在上一个实体，把标签 和 标记点 隐藏
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.show = false;
                console.log("隐藏标签---", this.previousEntity.id);
                let previousEntityDeviceImei = (this.previousEntity.id).split('point_');
                this.hideMarkerEntity(previousEntityDeviceImei[1]);
                // this.previousEntity.billboard.show = false;
            }

            // 显示标签
            targetEntity.label.show = true;
            // 
            // targetEntity.billboard.show = true;
            targetEntity.label.fillColor = Cesium.Color.RED;

            // 更新上一个实体的引用
            this.previousEntity = targetEntity;


            // 切换到目标实体
            window.viewer2DP.trackedEntity = targetEntity;
            // 显示选中框
            window.viewer2DP.selectedEntity = targetEntity;



        },
        startAnimation() { // TODO 开始播放
            window.viewer2DP.clock.shouldAnimate = true;

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer2DP.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;

            // 获取当前选中的实体
            var targetEntity = pointEntities[this.currentIndex];


            // 如果存在上一个实体，把标签隐藏
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.show = false;
            }

            // 显示标签
            targetEntity.label.show = true;
            targetEntity.label.fillColor = Cesium.Color.RED;

            // 更新上一个实体的引用
            this.previousEntity = targetEntity;

            // 切换到目标实体
            window.viewer2DP.trackedEntity = targetEntity;
            window.viewer2DP.selectedEntity = targetEntity;

        },
        pauseAnimation() {   // 暂停播放
            window.viewer2DP.clock.shouldAnimate = false;


        },
        resetAnimation() {  // TODO 重置播放

            window.viewer2DP.clock.currentTime = Cesium.JulianDate.fromDate(new Date(this.eventStartTime));
            // 重置实体索引
            this.currentIndex = -1;

            // 重置排名数据
            this.processTableData();

            let currentTime = Cesium.JulianDate.fromDate(new Date(1699403520000)); // 中国时间 开始时间
            //  TODO 设置时钟当前时间 改了下
            // window.viewer2DP.clock.currentTime = this.startTime.clone();
            // 设置指定时间  Cesium Viewer 的当前时间为这个 JulianDate
            window.viewer2DP.clock.currentTime = currentTime.clone();


            // 跳到地图起点
            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer2DP.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 400000),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(90),
                //     // 视角 x
                //     roll: -3.0,
                // },

                duration: 1, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }

            });

        },
        speedUpAnimation() {  // 加速播放
            window.viewer2DP.clockViewModel.multiplier *= 2;
        },
        speedDownAnimation() {   // 减速播放
            window.viewer2DP.clockViewModel.multiplier /= 2;
        },





    }

}
</script>

<style lang="less" scoped>
.body_div {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

#container {

    width: 100%;
    height: 100%;
}

/* .amap-ctrl-list-layer{
    right: 1108px;
  } */



.botOtherBox {


    position: absolute;
    width: 100%;
    bottom: 30px;
    /* left: 300px; */
    /* height: 60px; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* margin: 0 auto; */
    -ms-flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.botOtherItem {
    padding: 8px 18px;
    background: rgba(0, 9, 18, .5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.botOtherItem:hover {

    color: #1af2ff;

}




.input-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    width: 22rem;
    border-width: 0;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 1.25rem;
}

.input-card .btn {

    margin-right: 1.2rem;
    width: 9rem;
}

.input-card .btn:last-child {
    margin-right: 0;
}

/** 排名样式 */
.playBox {
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    user-select: none;
    height: 42px;
    z-index: 99;
}

.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.playBoxItem {
    width: 146px;
    height: 42px;
    padding: 0 15px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    line-height: 1;
    position: relative;
}

.playBoxItem:hover {
    color: #1af2ff;
}


.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.imgBox {
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

img {
    width: 100%;
    height: 100%;
}

.icons {
    font-size: 20px;
    transition: all .3s;
}

.el-icon {

    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: #fff;
    font-size: inherit;
}

.botBox {
    display: block;
    /* 改为block以使用绝对定位 */
    position: absolute;
    bottom: 100%;
    /* 初始位置在imgBox的下方，但我们将通过JS调整它 */
    left: 0;
    width: 146px;
    /* max-height不再需要，因为我们将使用v-if来控制显示和隐藏 */
    transition: all 0.3s;
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, 0.75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    overflow: hidden;
    /* 初始时隐藏，通过v-if控制 */
    /* 注意：由于我们使用了v-if，max-height属性不再需要 */
}

/* 添加过渡效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.botBtnItem {
    width: 100%;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

}

.botBtnItem:hover {
    color: #1af2ff;
}


/* 梯队数据样式 */
.echelonBox.on {
    transform: translate(0);
}

// TODO 表格样式
.echelonBox {
    // padding: 20px 30px 10px;
    width: 197px;
    background: rgba(0, 0, 0, .05);
    border-radius: 8px;
    font-size: 22px;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 5px;
    transition: all .3s;
    transform: translate(200%);
    max-height: 320px;
    z-index: 99;
    // user-select: none;
}

// 轨迹数据样式
.trackBox.on {
    transform: translate(0);
}

.trackBox {
    padding: 20px 30px 10px;
    width: 600px;
    background: rgba(0, 0, 0, .05);
    border-radius: 8px;
    font-size: 22px;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 30px;
    transition: all .3s;
    transform: translate(200%);
    z-index: 99;
    // user-select: none;
}

.drawer_font_size {
    font-size: 14px;
}

.dynamics_data {
    display: flex;
}

.dynamics_data_info {
    flex: 1;
    display: flex;

}



.el-icon {
    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: var(--color);
    font-size: inherit;
}

.titBoxImg {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.closePlay2 {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    height: 30px;
    background: #000;
    opacity: 0;
    border-radius: 50%;
    transition: all .3;
}


.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.infoBoxTit {
    margin-bottom: 20px;
    align-items: center;
    font-size: 28px;
    color: #fff;
    text-shadow: 0px 0px 10px #fff;
    line-height: 1;
}

.echelonItem {
    margin-bottom: 20px;
}

.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.echelonTit {
    font-size: 20px;
    color: #fff;
}

.echelonNum {
    font-size: 28px;
    color: #fff;
}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.homingTime {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.echelonCont {
    padding-right: 50px;
    width: 100%;
}

/* 赛鸽排名样式 */


.index_one {
    color: #fec412;
    display: none;
}

.index_two {
    color: #b4c0c7;
    display: none;
}

.index_three {
    color: #714e3a;
    display: none;
}


::v-deep {

    // .el-table th.el-table__cell {
    //     user-select: initial;
    //     }
    .el-table {
        margin-top: 0px;
    }

    .el-table,
    .el-table__expanded-cell {
        background-color: #ffffff00;
    }

    .el-table th.el-table__cell {
        background-color: #ffffff00;
    }

    .el-table thead {
        color: #ffffff;
    }

    .el-table tr {
        background-color: #ffffff00;
        color: #ffffff;
    }

    .el-table__empty-text {

        color: #ffffff;
    }

    //  .el-table--enable-row-hover .el-table__body tr:hover {
    //     background-color: #052531; 
    // }
    // 鼠标移上去
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #3498dbcb;
    }

    // // 设置滚动条颜色
    // .el-table__body-wrapper {
    //     scrollbar-width: thin; /* 或 'auto' */
    //     scrollbar-color: #00a0e9 #ffffff00; /* 滑块颜色 轨道颜色 */
    // }

    // .el-table--scrollable-y .el-table__body-wrapper ::-webkit-scrollbar {
    //     width: 10px; /*滚动条的宽*/
    //     height: 10px; /*滚动条的高*/
    // }
    // .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
    //     box-shadow: 0px 1px 3px #0558f3 inset; /*滚动条的背景区域的内阴影*/
    //     border-radius: 10px; /*滚动条的背景区域的圆角*/
    //     background-color: #4a0735; /*滚动条的背景颜色*/
    // }



    // 全屏
    .cesium-viewer-fullscreenContainer {
        position: absolute;
        /* bottom: 0; */
        top: 8px;
        right: 48px;
        padding: 0;
        width: 29px;
        height: 29px;
        overflow: hidden;
    }

    // 轨迹数据
    .el-drawer {
        background-color: #052531e0;

    }

}



// 比赛数据按钮
.mapGameBox {
    position: absolute;
    left: 16px;
    top: 15px;
    z-index: 100;
}

// 地图切换
.mapSwitchBox {
    position: absolute;
    right: 5px;
    bottom: 55px;
    z-index: 100;
}

.mapSwitch {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    background-color: hsla(0, 0%, 100%, .6666666666666666);
    padding: 2px 2px 2px 4px;
    margin-top: 10px;
}

.mapSwitchItem {
    width: 28px;
    height: 28px;
}

/**移动端样式 竖屏 */
// @media screen and (max-width: 768px) {
//     // 按钮
//     .mapSwitchBox {
//     position: absolute;
//         right: 5px;
//         bottom: 55px;
//         z-index: 100;
//     }

// }


/**移动端样式  横屏 */
/* 移动端样式 按钮 竖屏  max-width 768  ： 1-768 */
@media screen and (min-width: 768px) {

    // /* 按钮样式 */
    .mapSwitchBox {
        position: absolute;
        right: 16px;
        bottom: 75px;
        z-index: 100;
    }

    // TODO 表格样式
    .echelonBox {
        // padding: 20px 30px 10px;
        width: 197px;
        background: rgba(0, 0, 0, .05);
        border-radius: 8px;
        font-size: 22px;
        color: #fff;
        position: fixed;
        top: 10px;
        left: 10px;
        right: auto;
        transition: all .3s;
        transform: translate(200%);
        max-height: 320px;
        z-index: 99;
        // user-select: none;
    }

    .mapGameBox {
        position: absolute;
        left: auto;
        right: 85px;
        top: -6px;
        z-index: 100;
    }



}


// /* 电脑 端样式  数据表格 横屏 */
@media screen and (min-width: 992px) {

    // TODO 表格样式
    .echelonBox {
        // padding: 20px 30px 10px;
        width: 197px;
        background: rgba(0, 0, 0, .05);
        border-radius: 8px;
        font-size: 22px;
        color: #fff;
        position: fixed;
        top: 120px;
        left: auto;
        right: 5px;
        transition: all .3s;
        transform: translate(200%);
        max-height: 320px;
        z-index: 99;
        // user-select: none;
    }

    // 比赛数据按钮
    .mapGameBox {
        position: absolute;
        left: 16px;
        top: 15px;
        z-index: 100;
    }

    .drawer_font_size {
        font-size: 14px;
    }
}
</style>