<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-container>
          <!-- Header -->
          <el-header>
            <div class="header-content">
              <!-- <img id="logoImg" src="/mapGLB/logo_bg.png" /> -->
              <img id="logoImg" src="/image/logo.png" />
              <div class="race-name">
                <div class="raceNameLabel">比赛名称</div>
                <div class="raceNameLabel">2024决赛</div>
              </div>
              <div class="race-info"> 轨迹证书</div>
            </div>
          </el-header>

          <!-- Main -->
          <el-main>
            <div class="main-content">
              <!-- 内容区域 -->
              <!-- <div style="height: 1500px; background-color: blue;"></div> -->
              <el-table :data="trackTable" size="mini" class="table-ranking"
                :header-cell-style="{ color: '#333333', textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">




                <!-- 鸽环号 -->
                <!-- <el-table-column :label="$t('phoneLanguage.pigeonRingNumber')" prop="deviceImei" ></el-table-column> -->

                <el-table-column label="公环" prop="deviceImei"></el-table-column>
                <!-- 起点时间： -->
                <el-table-column label="起点时间" prop="stratTime"></el-table-column>
                <!-- 终点时间： -->
                <el-table-column label="终点时间" prop="endTime"></el-table-column>
                <!-- 飞行时长： -->
                <el-table-column :label="$t('computerLanguage.flightDuration')"
                  prop="gpsLocationTime"></el-table-column>


                <!-- 当前设备 -->
                <!-- <div>{{ $t('computerLanguage.currentDevice') }}：{{ currentSelectDevice }}</div> -->

                <!-- <div>{{ $t('computerLanguage.startPointTime') }}：{{ currentSelectDeviceStartTime }}</div> -->
                <!-- 终点时间： -->
                <!-- <div>{{ $t('computerLanguage.endPointTime') }}：{{ currentSelectDeviceEndTime }}</div> -->
                <!-- 飞行时长： -->
                <!-- <div>{{ $t('computerLanguage.flightDuration') }}：{{ currentSelectDeviceFlightduration }}</div> -->


              </el-table>
            </div>

            <!--TODO 2D地图 -->
            <div class="row">
              <div class="map-2D">
                <!-- "startLng","startLat","endLng","endLat" -->
                <initCesium2D :startLng="startLng" :trackData="trackData" :pigeonTrackData="pigeonTrackData" />

                <div style="height: 600px;">
                  <div style="position: absolute;left:50% ;transform: translateX(-50%);z-index: 2;">
                  <el-button type="primary" @click="googleMapHideMarkers()">隐藏标记点</el-button>
                  <el-button type="primary" @click="googleMapShowMarkers()">显示标记点</el-button>
                </div>
                  <!-- google地图-->
                  <GmapMap ref="mapRef" v-if="currentMap === 'google'" map-type-id="terrain" :center="center" :zoom="8"
                    style="width: 100%; height: 100%" :options="{
                      zoomControl: true, // 启用缩放控件
                      mapTypeControl: true, // 启用地图类型切换控件
                      scaleControl: true, //  启用比例尺控件
                      streetViewControl: true, // 启用街景
                      rotateControl: true, // 启用旋转手势
                      fullscreenControl: true, // 启用全屏
                      disableDefaultUI: false // 禁用默认UI控件
                    }">
                  </GmapMap>
                </div>

              </div>
            </div>



            <!-- 图表 -->
            <div class="row">
              <div class="map-2D">
                <div ref="mains" id="main" style="margin-top: 20px;width: 100%;height: 500px">
                </div>
                <div id="time-display"></div>
              </div>
            </div>

            <!-- 图表 -->
            <div class="row">
              <div class="map-2D">
                <div class="dynamics_data">
                  <div class="dynamics_data_info">
                    <!-- 平均分速 (m/Min): -->
                    <div>{{ $t('computerLanguage.averageSpeed_m_min') }}</div>
                    <div>{{ averageSpeed }}</div>
                  </div>
                  <div class="dynamics_data_info">
                    <!-- 最高分速 (m/Min): -->
                    <div>{{ $t('computerLanguage.maxSpeed_m_min') }}</div>
                    <div>{{ maxSpeed }}</div>
                  </div>
                </div>

                <div class="dynamics_data">
                  <div class="dynamics_data_info">
                    <!-- 平均高度 (m): -->
                    <div>{{ $t('computerLanguage.averageAltitude_m') }}</div>
                    <div>{{ averageAltitude }}</div>
                  </div>
                  <div class="dynamics_data_info">
                    <!-- 最大高度 (m): -->
                    <div>{{ $t('computerLanguage.maxAltitude_m') }}</div>
                    <div>{{ maxAltitude }}</div>
                  </div>
                </div>

                <div class="dynamics_data">
                  <div class="dynamics_data_info">
                    <!-- 实际距离 (km): -->
                    <div>{{ $t('computerLanguage.actualDistance_km') }}</div>
                    <div>{{ actualDistance }}</div>
                  </div>
                  <div class="dynamics_data_info">
                    <!-- 实际分速 (m/Min): -->
                    <div>{{ $t('computerLanguage.actualSpeed_m_min') }}</div>
                    <div>{{ actualSpeed }}</div>
                  </div>
                </div>

                <div class="dynamics_data">
                  <div class="dynamics_data_info">
                    <!-- 直线距离 (km): -->
                    <div>{{ $t('computerLanguage.straightLineDistance_km') }}</div>
                    <div>{{ lineDistance }}</div>
                  </div>
                  <div class="dynamics_data_info">
                    <!-- 直线分速 (m/Min): -->
                    <div>{{ $t('computerLanguage.straightLineSpeed_m_min') }}</div>
                    <div>{{ lineSpeed }}</div>
                  </div>
                </div>

                <div style=" border-top: 1px #b7b7b7 solid;">

                  <el-table :data="pigeonTableData" :header-cell-style="{ color: '#333333', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }" height="560" border style="width: 100%">


                    <!-- 分段资料 -->
                    <el-table-column :label="$t('computerLanguage.segmentData')">
                      <template slot-scope="scope">
                        {{ scope.$index + 1 }} <!-- $index 是从 0 开始的，所以加 1 以符合常规序号习惯 -->
                      </template>
                    </el-table-column>

                    <!-- 时间 -->
                    <el-table-column :label="$t('computerLanguage.time')">
                      <template slot-scope="scope">{{ formatGpsTime(scope.row.gpsTime) }}</template>
                      <!-- <template slot-scope="scope">{{ scope.row.gpsTime }}</template> -->
                    </el-table-column>


                    <!-- 累积时间 -->
                    <el-table-column prop="accumulateDuration" :label="$t('computerLanguage.accumulateTime')">
                      <template slot-scope="scope">{{ formatDuration(scope.row.accumulateDuration) }}</template>

                    </el-table-column>
                    <!-- 距离 m-->
                    <el-table-column prop="accumulateOdometer" :label="$t('computerLanguage.distance_m')">
                      <!-- calculateDistance -->
                      <!-- <template slot-scope="scope">
                            {{ calculateDistance(scope.row.lng,scope.row.lat) }}
                        </template>
    -->
                    </el-table-column>

                    <!-- 海拔 m-->
                    <el-table-column prop="altitude" :label="$t('computerLanguage.altitude_m')">
                    </el-table-column>

                    <!-- 速度 m/s -->
                    <el-table-column :label="$t('computerLanguage.speed_m_s')">
                      <template slot-scope="scope">
                        {{ calculateSpeed(scope.row.accumulateOdometer, scope.row.accumulateDuration) }}
                      </template>
                    </el-table-column>


                  </el-table>
                </div>
              </div>
            </div>
          </el-main>

          <!-- Footer -->
          <el-footer>Footer</el-footer>
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 引入ccs 适应手机屏幕 消除原有样式
// import '@/assets/css/track.css';
// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';

import moment from 'moment'
import 'moment-timezone';

// 引入组件
import initCesium2D from "./initCesium2D.vue";



import {
  getMultipleDeviceTraceLog
} from '@/api/gpsDevice'

import {
  initMap, moveToCenter,
  batchMakePathsAndMarkers,
  drawStartEndMarkersAndPath,
  showMarkers,
  hideMarkers,
  batchMakeCircleMarkers,
  makePath, makeColorPath, makeTrackMarker, cleanAllPath
} from '@/api/map';
import { deviceStatusFormat, getObjString, convertDateTimeToString } from '@/api/util'
export default {
  components: {
    initCesium2D,
  },
  data() {
    return {
      sessionStoragePigeonTrackData: [], // 获取缓存中的鸽子轨迹数据
      pigeonTableData: [],
      pigeonTrackData: null, // 鸽子轨迹数据
      startLng: 116.397428,
      center: {
        lat: 25.037798,
        lng: 121.565170
      }, // 地图中心点坐标
      currentMap: 'google', // 默认显示谷歌地图
      mapInstance: undefined,
      trackData: {
       
      },
      trackTable: [
        {
          deviceImei: undefined,
          stratTime: undefined,
          endTime: undefined,
          gpsLocationTime: undefined
        },
        // 其他数据...

      ],
      eventStartTime: 0, //   开始时间
      eventEndTime: 0,  // 结束时间

      poputotalDistance: 0, // 累计里程
      poputotalDuration: 0,  // 累计时长
      popuaverageSpeed: 0, // 平均速度

      // 平均分速
      averageSpeed: 0, // 平均速度
      // 最高分速
      maxSpeed: 0, // 最高速度
      averageAltitude: 0, // 平均海拔
      // 最大高度
      maxAltitude: 0, // 最大高度
      // 实际距离
      actualDistance: 0, // 实际距离
      // 实际分速
      actualSpeed: 0, // 实际速度
      // 直线距离
      lineDistance: 0, // 直线距离
      // 直线分速
      lineSpeed: 0, // 直线速度
    };
  },
  created() {

    // 获取当前系统语言
    const language = navigator.language || navigator.userLanguage;
    // 判断语言
    let systemLanguage;
    if (language.startsWith('zh')) {
      // 判断是简体中文还是繁体中文
      if (language === 'zh-CN' || language === 'zh-Hans' || language === 'zh') {
        systemLanguage = 'zh-CN';
      } else if (language === 'zh-TW' || language === 'zh-Hant') {
        systemLanguage = 'zh-TW';
      } else {
        systemLanguage = 'en-US';
      }
    } else if (language.startsWith('en')) {
      systemLanguage = 'en-US';
    } else {
      systemLanguage = 'en-US';
    }

    // console.log('系统语言是:', systemLanguage);

    // 打印当前系统语言
    // console.log("打印当前系统语言language", language);
    window.sessionStorage.setItem('lang', systemLanguage);

    // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
    const lang = window.sessionStorage.getItem('lang');

    // 设置 Vue I18n 的当前语言
    this.$i18n.locale = lang;


    // 初始化数据
    // this.testGetTrackData();
     // 获取缓存中的数据  
    this.sessionStoragePigeonTrackData = JSON.parse(window.sessionStorage.getItem('selectPigeonTrackData')) ;

     const dd = this.sessionStoragePigeonTrackData[0]
    // 设置终点起点
    this.trackData = {
      
      startLng: dd[0].lng,
      startLat: dd[0].lat,
      endLng: dd[dd.length -1 ].lng,
      endLat: dd[dd.length -1].lat,
    }

  },

  mounted() {
    
     // 获取缓存中的数据  sessionStoragePigeonTrackData
    // this.pigeonTrackData = JSON.parse(window.sessionStorage.getItem('selectPigeonTrackData')) ;
    this.pigeonTrackData = this.sessionStoragePigeonTrackData;
    this.pigeonTableData = this.sessionStoragePigeonTrackData[0]

    
    const dd = this.sessionStoragePigeonTrackData[0]
    // 设置终点起点
    const startPoint = { lat: dd[0].lat, lng: dd[0].lng, };
    const endPoint = { lat: dd[dd.length -1].lat, lng:dd[dd.length -1 ].lng };

    

    // console.log("获取缓存中的数据this.pigeonTrackData", this.pigeonTrackData);
    // console.log("获取缓存中的数据this.trackData", this.trackData);
    this.initTableData();
    this.initEcharts();

    let _this = this;
    // 可以在这里添加地图加载完成后的其他操作
    setTimeout(function () { // 延迟加载地图，防止页面抖动问题

      // pigeonTableData
      _this.initMapPage()
      console.log("this.pigeonTrackData", _this.pigeonTrackData);
      if (_this.pigeonTrackData && _this.pigeonTrackData.length > 0) {
       
        // 设置地图中心点
        moveToCenter(_this.mapInstance, _this.pigeonTrackData[0][0])
       
        batchMakePathsAndMarkers(_this.mapInstance, _this.pigeonTrackData)
                // 假设 mapInstance 是地图实例，startPoint 和 endPoint 是起点和终点的经纬度对象

                drawStartEndMarkersAndPath(_this.mapInstance, startPoint, endPoint);

       
      }
      
    }, 800)


  },
  methods: {
    // 显示Google地图标记点
    googleMapShowMarkers(){
      showMarkers(this.mapInstance)
    },
    // 隐藏Google地图标记点
    googleMapHideMarkers(){
      hideMarkers(this.mapInstance)
    },
    initEcharts() {
      // 从表格数据中提取时间、速度和高度数据
      const timeData = this.pigeonTableData.map(item => item.gpsTime);
      const speedData = this.pigeonTableData.map(item => this.calculateSpeed(item.accumulateOdometer, item.accumulateDuration));
      const heightData = this.pigeonTableData.map(item => item.altitude);


      console.log("timeData", timeData);
      console.log("speedData", speedData);
      console.log("heightData", heightData);


      //        // 提取时间、速度和高度数据
      // var timeData = data.map(item => item.gpsTime);
      // var speedData = data.map(item => item.speed);
      // var heightData = data.map(item => item.altitude);

      // 计算时间差（以分钟为单位）
      var startTime = new Date(timeData[0].replace(' ', 'T')).getTime();
      var timeDifferences = timeData.map(timeStr => {
        var currentTime = new Date(timeStr.replace(' ', 'T')).getTime();
        return (currentTime - startTime) / (1000 * 60); // 转换为分钟
      });

      // 计算x轴的最大值并确保是50的倍数
      var maxXValue = Math.ceil(Math.max(...timeDifferences) / 50) * 50;

      // 生成x轴的数据，每次增加50分钟
      var xAxisData = [];
      for (var i = 0; i <= maxXValue; i += 50) {
        xAxisData.push(i);
      }

      var option = {
        title: {
          text: '', // 主标题为空，避免重复显示
          textStyle: {
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'axis',
          alwaysShowContent: true,
          position: function (point, params, dom, rect, size) {
            // 将提示框位置固定在图表右下角
            return { right: '10%', bottom: 1 };
          },
          formatter: function (params) {
            var speedValue = null;
            var heightValue = null;
            var currentMinutes = params[0].axisValue;
            // 找出最接近当前分钟数的原始时间点
            var closestIndex = 0;
            var minDiff = Math.abs(timeDifferences[0] - currentMinutes);
            for (var k = 1; k < timeDifferences.length; k++) {
              var diff = Math.abs(timeDifferences[k] - currentMinutes);
              if (diff < minDiff) {
                minDiff = diff;
                closestIndex = k;
              }
            }
            var currentTime = timeData[closestIndex];
            for (var i = 0; i < params.length; i++) {
              if (params[i].seriesName === '速度') {
                speedValue = params[i].data[1];
              } else if (params[i].seriesName === '高度') {
                heightValue = params[i].data[1];
              }
            }
            // 更新右上角的时间显示
            document.getElementById('time-display').innerText = '时间: ' + currentTime;
            return '<span style="color: #007AFF;">速度&nbsp;' + (speedValue !== null ? speedValue.toFixed(2) : '无数据') + '</span>&nbsp;&nbsp;' +
              '<span style="color: #FF5733;">高度 ' + (heightValue !== null ? heightValue.toFixed(2) : '无数据') + '</span>';
          }
        },
        legend: {
          show: true // 显示图例
        },
        xAxis: {
          type: 'value', // 改为 value 类型
          name: '時間 (Min)', // 改为繁体中文
          nameTextStyle: {
            fontSize: 20,
            color: '#000',
            align: "left",
            verticalAlign: "top",
            lineHeight: 80,
            padding: [0, 0, 0, 100]
          },
          position: '',
          boundaryGap: false,
          nameLocation: "start",
          nameGap: 30,
          offset: 10,
          min: 0, // 最小值为 0
          max: maxXValue, // 最大值为计算的最大值
          interval: 50, // 每 50 分钟显示一个刻度
          axisLine: {
            show: true,
            onZero: false,
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            onZero: false,
            show: true,
            interval: 0, // 强制显示所有标签
            rotate: 0, // 不旋转标签
            fontSize: 12, // 设置标签字体大小
            formatter: function (value) {
              // 直接显示分钟数
              return value;
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            name: '速度(m/Min)',
            position: 'left',
            nameLocation: "middle",
            nameGap: 50,
            offset: 10,
            nameTextStyle: {
              fontSize: 20,
              color: '#007AFF'
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: '#007AFF',
                width: 1,
                type: 'solid'
              }
            },
            splitLine: {
              show: false // 隐藏网格线，图片中未明显显示
            },
            axisTick: {
              show: true,
              length: 5, // 刻度线长度，可根据需要调整
              lineStyle: {
                color: '#007AFF', // 刻度线颜色
                width: 1 // 刻度线宽度
              }
            }
          },
          {
            type: 'value',
            name: '高度(M)',
            position: 'right',
            nameLocation: "middle",
            offset: 10,
            nameGap: 50,
            nameTextStyle: {
              fontSize: 20,
              color: '#FF5733'
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: '#FF5733',
                width: 1,
                type: 'solid'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true,
              length: 5, // 刻度线长度，可根据需要调整
              lineStyle: {
                color: '#FF5733', // 刻度线颜色
                width: 1 // 刻度线宽度
              }
            }
          }
        ],
        series: [
          {
            name: '速度',
            type: 'line',
            data: timeDifferences.map((time, index) => [time, speedData[index]]),
            yAxisIndex: 0,
            symbol: 'none',
            lineStyle: {
              color: '#007AFF',
              width: 1 // 调整线条宽度
            }
          },
          {
            name: '高度',
            type: 'line',
            data: timeDifferences.map((time, index) => [time, heightData[index]]),
            yAxisIndex: 1,
            symbol: 'none',
            lineStyle: {
              color: '#FF5733',
              width: 1
            }
          }
        ]
      };

      this.chartInstance = this.$echarts.init(this.$refs.mains);
      this.chartInstance.setOption(option)

    },
    initTableData() {
      if (this.pigeonTrackData[0].length > 0) {
        // const jsonData = JSON.parse(sessionPigeonData);
        // this.pigeonTableData = jsonData[0];
        this.pigeonTableData = this.pigeonTrackData[0]
        // this.test = jsonData;

        // 初始化地图数据
        if (this.pigeonTableData) {
          const length = this.pigeonTableData.length;
          // console.log('找到设备'+this.pigeonTableData[0].deviceImei);
          // this.currentSelectDeviceStartTime = this.pigeonTableData[0].gpsTime;
          // this.currentSelectDeviceEndTime = this.pigeonTableData[length - 1].gpsTime;
          // this.currentSelectDeviceFlightduration = this.formatDuration(this.pigeonTableData[length - 1].accumulateDuration);

          this.trackTable[0].deviceImei = this.pigeonTableData[0].deviceImei;
          this.trackTable[0].stratTime = this.pigeonTableData[0].gpsTime;
          this.trackTable[0].endTime = this.pigeonTableData[length - 1].gpsTime;
          this.trackTable[0].gpsLocationTime = this.formatDuration(this.pigeonTableData[length - 1].accumulateDuration);

          let totalDistance = 0; // 累计里程
          let totalDuration = 0;  // 累计时长
          let maxDistance = 0;


          // 平均分速
          // averageSpeed: 0, // 平均速度
          this.pigeonTableData.forEach(log => {
            // 累计里程和时长（这里假设每个日志都是一个新的行程开始和结束）
            // 注意：这里的计算方式可能并不准确，因为缺少每个行程的开始时间和里程
            totalDistance += log.accumulateOdometer; // 这可能是从起点到这里的累计里程
            totalDuration += log.accumulateDuration; // 这可能是从起点到这里的累计时长（单位可能是秒）


          });
          this.averageSpeed = (totalDistance / totalDuration * 60).toFixed(2);
          // // 最高分速
          // 优化 数据可能为空
          this.maxSpeed = Math.max(...this.pigeonTableData.map(log => {
            const odometer = log.accumulateOdometer || 0; // 如果为空或未定义，则默认为0
            const duration = log.accumulateDuration || 1; // 防止除以零，如果未定义则默认为1（或选择其他合适的值）
            const speed = (odometer > 0 && duration > 0) ? ((odometer / duration) * 60).toFixed(2) : '0.00';
            return parseFloat(speed); // 返回浮点数用于Math.max计算，因为toFixed返回的是字符串
          }));

          // averageAltitude: 0, // 平均海拔
          // // 最大高度
          // maxAltitude: 0, // 最大高度
          // // 实际距离
          // actualDistance: 0, // 实际距离
          let total = 0;
          let totalTime = 0;
          for (let i = 1; i < this.pigeonTableData.length; i++) {
            const currentOdometer = this.pigeonTableData[i].accumulateOdometer;
            const previousOdometer = this.pigeonTableData[i - 1].accumulateOdometer;
            // 总距离 = 后一个 - 上一个
            total += currentOdometer - previousOdometer;

            // 总时长
            const currentDuration = this.pigeonTableData[i].accumulateDuration;
            const previousDuration = this.pigeonTableData[i - 1].accumulateDuration;
            totalTime += currentDuration - previousDuration;
          }

          this.actualDistance = (total / 1000).toFixed(2);
          // // 实际分速
          // actualSpeed: 0, // 实际速度 
          this.actualSpeed = (total / totalTime * 60).toFixed(2);

          // 直线距离 起点经纬度 到 终点 经纬度 距离
          // lineDistance: 0, // 直线距离
          let startPosition = Cesium.Cartesian3.fromDegrees(this.pigeonTableData[0].lng, this.pigeonTableData[0].lat);
          let endPosition = Cesium.Cartesian3.fromDegrees(this.pigeonTableData[length - 1].lng, this.pigeonTableData[length - 1].lat);
          let resultDistance = Cesium.Cartesian3.distance(startPosition, endPosition)
          this.lineDistance = (resultDistance / 1000).toFixed(2);
          // // 直线分速
          // lineSpeed: 0, // 直线速度
          this.lineSpeed = (resultDistance / totalTime * 60).toFixed(2);
          // 平均海拔高度
          const totalAltitude = this.pigeonTableData.reduce((sum, log) => sum + log.altitude, 0);
          this.averageAltitude = (totalAltitude / length).toFixed(2);
          // 最大高度
          this.maxAltitude = Math.max(...this.pigeonTableData.map(log => log.altitude));



        }

        // console.log("this.pigeonData", jsonData);
      } else {
        //
        // console.log("测试获取轨迹数据-->", sessionPigeonData);
      }
    },

    initMapPage() {
      const self = this;
      if (self.currentMap === 'google' && window.google && window.google.maps) {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            polyList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeInfoWindowConetnt,
            haveBtn: true
          }
        }
        // 异步执行初始化地图
        initMap(self.mapInstance, self.center, 8);
        // console.log('异步执行初始化地图');
        // if (self.pigeonTrackData && self.pigeonTrackData.length > 0) {
        //   // 设置地图中心点
        //   moveToCenter(self.mapInstance, self.pigeonTrackData[0][0])
        //   batchMakePathsAndMarkers(self.mapInstance, self.pigeonTrackData)
        // }
      } else {
        console.error('谷歌地图API未加载或当前地图类型不是谷歌地图');
        // 可以在这里添加错误处理逻辑，例如显示错误提示
      }
    },

    makeInfoWindowConetnt(device, haveBtn) {
      const self = this
      return function () {
        let content = '<style>'
        content += '.deviceInfo { font-size: 12px; margin: 0; padding: 0;}'
        content += 'ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; }'
        content += 'ul li div { width: 200px; }'
        content += '.centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; }'
        content += '.centerDiv button { font-size: 12px; width: 60px; border-radius: 10px; color: white; }'
        content += '.buluButton { border: 1px solid #00A2E9; background-color: rgba(0, 162, 240 ,0.5); }'
        content += '.orangeButton { border: 1px solid chocolate; background-color: rgba(210, 105, 30, 0.5); } '
        content += '.redButton { border: 1px solid red; background-color: rgba(233, 10, 10, 0.5); }'
        content += '.purpleButton { border: 1px solid purple; background-color: rgba(128, 0, 128, 0.5); }'
        content += '.yellowButton { border: 1px solid #F0AC20; background-color: rgba(242, 120, 32, 0.5); }'
        content += '</style>'
        content += '<ul class="deviceInfo">'
        if (device.deviceType !== 2) {
          let speed = 0
          if (device.accumulateOdometer && device.accumulateDuration && device.accumulateDuration !== 0) {
            speed = device.accumulateOdometer / device.accumulateDuration
          }

          self.poputotalDistance += device.accumulateOdometer; // 这可能是从起点到这里的累计里程
          self.poputotalDuration += device.accumulateDuration; // 这可能是从起点到这里的累计时长（单位可能是秒）
          self.popuaverageSpeed = self.poputotalDistance / self.poputotalDuration

          content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
          content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (device.lastDeviceVol || 0) + '%' + '</span> </div>'
          content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (device.version || '') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + device.gpsTime + '</span> </div>'
          content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + device.lastLocationTime + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + device.lat + '</span> </div>'
          content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + device.lng + '</span> </div> </li>'
          // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
          // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (device.accumulateOdometer || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (device.accumulateDuration || 0) + self.$t('unit.second') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (self.averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (self.averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (device.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (device.pointIndex || '') + '</span> </div> </li>'
        }
        if (haveBtn) {
          content += '<li> <div class="centerDiv">'
          content += '<button class="buluButton" onclick="realTimeTrack(\'' + device.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
          content += '<button class="orangeButton" onclick="historicalTrack(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
          content += '<button class="redButton" onclick="flightTraining(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.flightTraining') + '  </button>'
          // content += '<button onclick="sendCommand(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
          content += '<button class="purpleButton" onclick="modifyConfiguration(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.config') + '  </button>'
          content += '<button class="yellowButton" onclick="getDeviceInfo(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
          content += '</div> </li>'
        }
        content += '</ul>'
        return content
      }
    },
    // 测试请求轨迹数据
    testGetTrackData() {

      //             {
      //   "deviceImei": "867585332228289",
      //   "startTime": "2023-11-08 08:30:00",
      //   "endTime": "2023-11-08 11:30:00",
      //   "requestRows": 1000,
      //   "nextId": 0
      // }
      let startTime = moment.tz("2023-11-08 08:30:00", 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
      let endTime = moment.tz("2023-11-08 11:30:00", 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');


      // TODO 这里赛事没有数据
      //   let startTime =   moment(new Date() ).format("yyyy-MM-DD 00:00:00")
      // let endTime =   moment(eventEndTime ).format("yyyy-MM-DD 23:59:59")

      console.log('开始查询轨迹数据 这里赛事没有数据--->', startTime, endTime)
      let jsonArray = []

      // let deviceImei = listData[i]
      // if (this.deviceMap.has(deviceImei) && '1' === this.deviceMap.get(deviceImei)) {
      // let trackList = this.trackListMap.get(deviceImei)
      let jsonObject = {}
      jsonObject['deviceImei'] = '867585332228289'
      jsonObject['startTime'] = startTime
      jsonObject['endTime'] = endTime
      jsonObject['requestRows'] = 1000
      jsonObject['nextId'] = 0

      jsonArray.push(jsonObject)


      console.log('拼装的请求数据jsonArray', jsonArray.length, jsonArray)
      // if (jsonArray.length) {
      //   this.onMultipleDeviceTraceLog(jsonArray)
      //   return
      // }
      // 发送请求
      this.onMultipleDeviceTraceLog(jsonArray);
    },

    async onMultipleDeviceTraceLog(jsonArray) {
      // 开始 结束 时间搓
      let startTimeStamp = 1699403400000;
      let endTimeStamp = 1699414200000;

      let startTime = moment.tz("2023-11-08 08:30:00", 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
      let endTime = moment.tz("2023-11-08 11:30:00", 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');

      await getMultipleDeviceTraceLog(jsonArray).then(res => {
        if (res.data.code === 0) {
          let dataList = res.data.data  // 每拿到数据 每条 数据 假设10个
          // console.log('轨迹数据dataList', dataList.length)
          if (dataList && dataList.length) {
            let isHasTrack = false
            // 循环 在从 list 中获取数据
            // 先重治数据
            // 没十条
            var testArrayData = [];

            dataList.forEach((element, rank) => {

              if (element && element.list && element.list.length) {
                isHasTrack = true

                this.latLngDataTest = [];




                // 循环 每个条数据
                element.list.forEach((item, indx) => {

                  if (indx == 0) {
                    // 添加第一条数据
                    // console.log('添加第一条数据',element.list.length );
                    const startData = {
                      pointIndex: element.list.length,
                      rank: item?.rank ? item.rank : rank + 1,
                      logId: item.logId,
                      deviceImei: item.deviceImei,
                      deviceName: item.deviceName,
                      // lng: 1.2,
                      // lat: 22.2,
                      lng: (this.trackData.startLng - 0),
                      lat: (this.trackData.startLat - 0),
                      altitude: item?.altitude ? item.altitude : 0,
                      ringNo: item?.ringNo || 0,
                      gpsLocationTime: (startTimeStamp - 0),
                      speed: item?.speed ? item.speed : 0,
                      gpsTime: startTime,
                      deviceVol: item?.deviceVol ? item.deviceVol : 0,
                      deviceVol: item?.deviceVol ? item.deviceVol : 0,
                      accumulateDuration: 0, // 飞行时长
                      accumulateOdometer: 0,// 飞行距离

                    };
                    this.latLngDataTest.push(startData);

                  } else if (element.list.length == indx + 1) {
                    // console.log('最后一个点',element.list.length );
                    // console.log('最后一个点',indx );
                    // console.log('最后一个点item',item );


                    const startData = {
                      pointIndex: element.list.length,
                      rank: item?.rank ? item.rank : rank + 1,
                      logId: item.logId,
                      deviceImei: item.deviceImei,
                      deviceName: item.deviceName,
                      lng: item.lng, lat: item.lat,
                      altitude: 0,
                      ringNo: item?.ringNo || 0,
                      gpsLocationTime: (endTimeStamp - 0),
                      speed: item?.speed ? item.speed : 0,
                      gpsTime: item.gpsTime,
                      deviceVol: item?.deviceVol ? item.deviceVol : 0,
                      accumulateDuration: item?.accumulateDuration || 0, // 飞行时长
                      accumulateOdometer: item?.accumulateOdometer || 0,// 飞行距离



                    };
                    this.latLngDataTest.push(startData);

                  } else {
                    // TODO 拼装数据 
                    const lnglat = {
                      pointIndex: element.list.length,
                      // 排名
                      rank: item?.rank ? item.rank : rank + 1,
                      logId: item.logId,
                      deviceImei: item.deviceImei,
                      deviceName: item.deviceName,
                      lng: item.lng, lat: item.lat,
                      altitude: item?.altitude ? item.altitude : 0,
                      ringNo: item?.ringNo || 0,
                      gpsLocationTime: item.gpsLocationTime,
                      // TODO 这里 需要优化 转换时间
                      // gpsLocationTime: this.setStringTimeToTimeStamp(item.gpsTime),

                      speed: item?.speed ? item.speed : 0,
                      gpsTime: item.gpsTime,
                      deviceVol: item?.deviceVol ? item.deviceVol : 0,
                      deviceVol: item?.deviceVol ? item.deviceVol : 0,

                      accumulateDuration: item?.accumulateDuration || 0, // 飞行时长
                      accumulateOdometer: item?.accumulateOdometer || 0,// 飞行距离

                    };

                    // console.log('拼装后的每次10条？lnglat', lnglat)
                    this.latLngDataTest.push(lnglat)
                  }



                })



                testArrayData.push(this.latLngDataTest)
              }

            })

            // 子组件发送数据
            //   this.$emit('onEventigeonList', testArrayData)
          }


          // console.log('拼装后的经纬度数据为:-home', this.latLngDataTest)
          console.log('拼装后的每次10条？', testArrayData)
          // this.pigeonTrackData = testArrayData;

          
          // 拿到数据 初始化地图数据
          // this.initPigeonRaceList(testArrayData);
          // TODO 测试获取轨迹数据
          this.initTableData();


        

        }

      }).catch(e => {
        console.log('轨迹数据查询失败', e)
        // this.queryDeviceList.splice(0, this.queryDeviceList.length)
        // this.startSearchTrackLog()

        //  提示失败
        this.$message.error('轨迹数据查询失败')

      })
    },

    // 其他方法...
    // TODO 轨迹数据
    // TODO 计算速度 = (当前位置-上一个位置) / (当前时间 - 上一个时间);
    calculateSpeed(odometer, duration) {
      // 确保 odometer 不为空，以避免除以0的错误
      if (!odometer || odometer === 0) {
        return 0;
      }
      // 确保duration不是0，以避免除以0的错误
      return duration === 0 ? 0 : (odometer / duration * 60).toFixed(2) - 0; // 保留两位小数
    },
    // 格式化GPS时间
    formatGpsTime(gpsTime) {
      // "2023-11-08 08:30:00"
      const [date, time] = gpsTime.split(' ');
      return time;
    },
    // 格式累积时间
    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
    },
    padZero(number) {
      return number.toString().padStart(2, '0');
    },
  },

};
</script>

<style lang="less" scoped>
.raceNameLabel {

  font-size: 18px;
  margin-left: 20px;
  margin-top: 10px;
}

/* 全局样式 */
.el-header {
  background-color: #2196F3;
  color: #fff;
  line-height: 50px;
  padding: 0 20px;
  border-top: 5px solid #1E3D59;
  border-bottom: 5px solid #1E3D59;

}

.el-main {
  padding: 20px;
  background-color: #fff;
}

.el-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: 60px;
}

/* Header 内容布局 */
.header-content {
  display: flex;
  // align-items: center;
}

#logoImg {
  height: 50px;
  margin-right: 20px;
}

.race-name {
  display: flex;
  flex-direction: row;
}


.row {
  border-top: 1px #b7b7b7 solid;
  margin-right: -15px;
  margin-left: -15px;
}

// 地图
.map-2D {
  position: relative; // 相对定位
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

}


.dynamics_data {
  display: flex;
}

.dynamics_data_info {
  flex: 1;
  display: flex;

}


/* 响应式布局 */
@media screen and (max-width: 768px) {


  .el-header {
    line-height: 40px;
    height: 40px !important;
  }



  .el-footer {

    line-height: 40px;
  }

  .header-content {
    flex-direction: row;
    align-items: flex-start;
  }

  #logoImg {
    height: 30px;

  }

  .raceNameLabel {
    font-size: 14px;
    margin-top: 0px;
  }

  .el-main {
    padding: 10px;
  }
}


#main {
  width: 100%;
  height: 600px;
  position: relative;
}

#time-display {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  box-sizing: border-box;
}
</style>