<template>
    <div>
        <!-- 初始化2D 地图 公共组件 -->
        <div id="cesiumContainer2D"></div>
        <span>
            <!-- {{ pigeonTrackData }} -->
            <!-- {{ trackData }} -->
        </span>
    </div>
</template>

<script>
export default {
    name: 'Map2D',
    props:["trackData","pigeonTrackData","startLng","startLat","endLng","endLat"],
    watch: {
    pigeonTrackData(newVal) {
      if (newVal) {
        console.log(' 可以在这里处理接收到的新数据 Data received:', newVal);
        // 可以在这里处理接收到的新数据
        // this.pigeonTrackData = newVal;
        this.addLine(newVal);
      }
    }
  },
    data() {
        return {
             // 用于存储已生成的颜色
             generatedColors: new Set(),
        }
    },
    mounted() {
        this.initCesium2D();

       
    },
    created() {
        // 轨迹数据 点
        // this.addLine(this.pigeonTrackData);  
    },
    methods: {
        // TODO 初始化2D 地图
        initCesium2D() {

            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");



            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            const lang = window.sessionStorage.getItem('lang');

            if (lang !== 'en-US') { //  中文
                window.viewer2D = new Cesium.Viewer('cesiumContainer2D', {
                    homeButton: false,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: false, // 是否显示动画控件 去掉会报错，关联了模型移动 用css 把他隐藏了
                    infoBox: true, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                });

                // 如果需要叠加高德/百度注记地图则添加以下代码
                window.viewer2D.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                    url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    layer: "tdtAnnoLayer",
                    style: "default",
                    format: "image/jpeg",
                    tileMatrixSetID: "GoogleMapsCompatible"
                }))

            } else {  // 英文标注

                window.viewer2D = new Cesium.Viewer('cesiumContainer2D', {
                    homeButton: false,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: false, // 是否显示动画控件 
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                    // 英文标注
                    baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),

                });
            }

            let startPosition = Cesium.Cartesian3.fromDegrees(this.trackData.startLng, this.trackData.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.trackData.endLng, this.trackData.endLat);
            console.log(startPosition, endPosition);
            this.createGroundLineAndBillboards(startPosition, endPosition);


            

            // 在Viewer实例创建后，立即将场景模式设置为2D
            window.viewer2D.scene.mode = Cesium.SceneMode.SCENE2D;
            // window.viewer2D.camera.enabled = false; // 关闭三维场景的相机
            // 显示帧率组件
            //  window.viewer.scene.debugShowFramesPerSecond = true;

              //是否开启抗锯齿
              window.viewer2D.scene.fxaa = true;
            window.viewer2D.scene.postProcessStages.fxaa.enabled = false;





            // window.viewer2D.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // 解决浏览器缩放后场景变形模糊的问题 useBrowserRecommendedResolution如果为true，
            // 则以浏览器建议的分辨率进行渲染，并忽略 window.devicePixelRatio（浏览器缩放比例）
            // window.viewer2D.useBrowserRecommendedResolution = true;

            // 校验如果是电脑端 开启抗锯齿
            const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

            // 简单的User Agent检查示例
            if (/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
                // 这是移动设备（手机或平板）
                // this.device = /ipad|tablet/i.test(userAgent.toLowerCase()) ? 'tablet' : 'phone';
                console.log('移动端', userAgent);
                // window.alert(userAgent)
                window.viewer2D._cesiumWidget._supportsImageRenderingPixelated = Cesium.FeatureDetection.supportsImageRenderingPixelated();
                window.viewer2D._cesiumWidget._forceResize = true;
                if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
                    var vtxf_dpr = window.devicePixelRatio;
                    // 适度降低分辨率
                    while (vtxf_dpr >= 2.0) {
                        vtxf_dpr /= 2.0;
                    }
                    //alert(dpr);
                    window.viewer2D.resolutionScale = vtxf_dpr;
                }
                window.viewer2D.scene.globe.maximumScreenSpaceError = 4 / 3;

            } else {
                // 这是桌面设备
                console.log('桌面端', userAgent);
                // this.device = 'desktop';
                window.viewer2D.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿

                // 2 js 性能优化 降低屏幕空间误差，提高渲染效率
                window.viewer2D.scene.globe.maximumScreenSpaceError = 1.5;
                window.viewer2D.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
                window.viewer2D.resolutionScale = window.devicePixelRatio; // 分辨率
            }


            // // 轨迹数据 点
            // this.addLine(this.pigeonTrackData);



        },


        // TODO 封装方法 起点终点 绘制贴地线
        createGroundLineAndBillboards(startPosition, endPosition) {
            // 创建贴地线
            var instance = new Cesium.GeometryInstance({
                geometry: new Cesium.GroundPolylineGeometry({
                    positions: [
                        startPosition,
                        endPosition
                    ],
                    width: 1.0
                }),
                id: 'obj_groundPolyline'
            });

            window.viewer2D.scene.groundPrimitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instance,
                appearance: new Cesium.PolylineMaterialAppearance({
                    material: Cesium.Material.fromType('#FF00ff')
                })
            }));

            


            let startPositi = Cesium.Cartesian3.fromDegrees(this.trackData.startLng, this.trackData.startLat, 250000.0);
            let endPositi = Cesium.Cartesian3.fromDegrees(this.trackData.endLng, this.trackData.endLat, 250000.0);
            // 计算中点
            let midpoint = Cesium.Cartesian3.midpoint(startPositi, endPositi, new Cesium.Cartesian3());


            window.viewer2D.camera.flyTo({
                destination: midpoint,
                duration: 1, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });






            // 在起点添加广告牌
            window.viewer2D.entities.add({
                position: startPosition,
                billboard: {
                    image: '/mapGLB/flagStart.png',
                    scale : 0.5,       // 大小
                    // alignedAxis: Cesium.Cartesian3.UNIT_Z, // 设置广告牌始终面向相机
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                    // sizeInMeters: true,  // 大小是否以米为单位
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                    // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                },
                // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                // model: {
                //     uri: './mapGLB/start2.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                //     // scale: 1, // 
                //     minimumPixelSize: 128,  // 最小的模型像素
                //     maximumScale: 200,
                //     //   runAnimations: false,//是否显示动画
                //     // clampAnimations: true,//是否保持最后一针的动画
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     horizontalOrigin: Cesium.HorizontalOrigin.BOTTOM, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                //     pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                //     eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                //     runAnimations: false,//是否显示动画
                //     // clampAnimations: true,//是否保持最后一针的动画


                // },
                label: {
                    text: this.$t('phoneLanguage.startPoint'),
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小  font: '24px Helvetica',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Material.fromType('#00ff00'),
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -40), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });


            // // 在终点添加广告牌
            window.viewer2D.entities.add({
                position: endPosition,
                billboard: {
                    image: '/mapGLB/flagEnd.png',
                    scale : 0.5,       // 大小
                    // sizeInMeters: true,  // 大小是否以米为单位
                    // horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    // verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                    // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                    // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                },
                // model: {
                //     uri: './mapGLB/end3.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                //     scale: 1, // 
                //     minimumPixelSize: 128,  // 最小的模型像素
                //     maximumScale: 200,
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                //     eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                //     //   runAnimations: false,//是否显示动画
                //     // clampAnimations: true,//是否保持最后一针的动画

                // },
                label: {
                    text: this.$t('phoneLanguage.endPoint'),

                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.RED,
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, 26), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });



        },

         // 添加轨迹
        //  addLine(vals) {
        //     console.log("遍历每个线的坐标点数组", vals);
        //     // 遍历每个线的坐标点数组
        //     vals.forEach((val, rank) => {
        //         console.log("遍历每个线的坐标点数组", val);
        //         let positions = [];
        //         let markers = []; // 用于存储标记点实体
        //         let previousPosition = null; // 用于存储上一个点的位置
        //         let previousTime = null;
        //         let myColor = this.getRandomColor();
        //         // 遍历当前线的每个坐标点
        //         val.forEach((item, index) => {


        //             const height = (item?.altitude != null) ? (item.altitude) : 0;
        //             // 将经纬度转换为Cesium的Cartesian3坐标
        //             const position = Cesium.Cartesian3.fromDegrees(item.lng, item.lat, height);
        //             positions.push(position);

                   

        //         // console.log("遍历每个线的坐标点数组",val[0]);
        //         // 在viewer中添加一个新的线实体
        //         window.viewer2D.entities.add({
        //             id: "polyline_" + val[0].deviceImei,
        //             polyline: {
        //                 positions: positions,

        //                 // 线宽
        //                 width: 5,
        //                 heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
        //                 // 路线颜色
        //                 material: Cesium.Color.fromCssColorString(myColor),
        //                 // 是否贴地
        //                 clampToGround: true,
        //             },
        //         });

        //     });

        //     });
        // },
        addLine(vals) {
            // 遍历每个线的坐标点数组
            vals.forEach((val, rank) => {
                let positions = [];
                let markers = []; // 用于存储标记点实体
                let previousPosition = null; // 用于存储上一个点的位置
                let previousTime = null;
                let myColor = this.getRandomColor();

                console.log("遍历每个线的坐标点数组", myColor, val);
                // 遍历当前线的每个坐标点
                val.forEach((item, index) => {


                    const height = (item?.altitude != null) ? (item.altitude) : 0;
                    // 将经纬度转换为Cesium的Cartesian3坐标
                    const position = Cesium.Cartesian3.fromDegrees(item.lng, item.lat, height);
                    positions.push(position);

                    // let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
                    let endPosition = Cesium.Cartesian3.fromDegrees(this.trackData.endLng, this.trackData.endLat);
                    // 时间  "gpsLocationTime": 1699403483000,

                    // // TODO 计算 当前位置和终点的距离
                    // // 计算距离 保留2位小数
                    let distance = (Cesium.Cartesian3.distance(position, endPosition) / 1000).toFixed(2);
                    // 计算速度 m/min 当前位置与上一个点的距离除以时间


                    // 解析GPS定位时间（这里假设gpsLocationTime是一个可以解析为Date对象的字符串）
                    const currentTime = new Date(item.gpsLocationTime);

                    // 如果不是第一个点，则计算速度和距离
                    if (previousPosition && previousTime) {
                        // 计算当前点与上一个点之间的距离
                        const distanceToPrevious = Cesium.Cartesian3.distance(position, previousPosition);

                        // 计算时间差（以毫秒为单位），然后转换为分钟
                        const timeDifferenceMilliseconds = currentTime - previousTime;
                        const timeDifferenceMinutes = timeDifferenceMilliseconds / (1000 * 60);

                        // 计算速度（米/分钟）校验 speed 不是 NaN

                        let speed = (distanceToPrevious / timeDifferenceMinutes).toFixed(2);
                        if (isNaN(speed)) {
                            speed = 0;
                        }
                        // TODO 创建标记点实体
                        markers.push({
                            // 可以为每个标记点添加一个唯一的id或名称，方便后续管理
                            id: `marker_` + item.deviceImei + '_' + index,
                            show: true,
                            position: position,
                            // point: {
                            //     // 标记点大小
                            //     pixelSize: 10,
                            //     // 标记点颜色
                            //     color: Cesium.Color.fromCssColorString(this.getRandomColor()),
                            //     // 高度参考
                            //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                            // },
                            //                     // 图片
                            billboard: {
                                color: Cesium.Color.fromCssColorString(myColor),
                                //mark_gps.png  bike_online.png
                                image: '/mapGLB/mark_gps.png',
                                scale: 0.5,       // 大小
                                // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                                // sizeInMeters: true,  // 大小是否以米为单位
                                horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                                // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                                // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                                // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                                // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                                // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                                // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                                // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                            },
                            // TODO 自定标记点数据
                            //   2024-26-0187343
                            // 時間：2024-11-30 11:48:48
                            // 速度：1020m/min
                            // 方向：西
                            // 海拔：-17m
                            // 名次：2
                            // 速度怎么算？ 1020m/min = 1020/60 = 17.33m/s

                            userData: {
                                description: 'This is a description of my entity.' + index,
                                additionalInfo: 'Here is some additional information.' + index,
                                lng: item.lng,
                                lat: item.lat,
                                height: height,
                                distance: distance,
                                time: item.gpsTime,
                                speed: speed,
                                ranking: rank + 1,
                                deviceVol: item.deviceVol,  // 电量
                                // 你可以添加更多自定义属性
                            },

                        });


                    } else {
                        // TODO 创建标记点实体
                        markers.push({
                            // 可以为每个标记点添加一个唯一的id或名称，方便后续管理
                            id: `marker_` + item.deviceImei + '_' + index,
                            show: true,
                            position: position,
                            // point: {
                            //     // 标记点大小
                            //     pixelSize: 10,
                            //     // 标记点颜色
                            //     color: Cesium.Color.fromCssColorString(this.getRandomColor()),
                            //     // 高度参考
                            //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                            // },
                            //                     // 图片
                            billboard: {
                                color: Cesium.Color.fromCssColorString(myColor),

                                image: '/mapGLB/mark_gps.png',
                                scale: 0.5,       // 大小
                                // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                                // sizeInMeters: true,  // 大小是否以米为单位
                                horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                                // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                                // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                                // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                                // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                                // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                                // translucencyByDistance: new Cesium.NearFarScalar(1000000, 1, 10000, 0), // 近远效果 透明度
                                // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                            },
                            // TODO 自定标记点数据
                            //   2024-26-0187343
                            // 時間：2024-11-30 11:48:48
                            // 速度：1020m/min
                            // 方向：西
                            // 海拔：-17m
                            // 名次：2
                            // 速度怎么算？ 1020m/min = 1020/60 = 17.33m/s

                            userData: {
                                description: 'This is a description of my entity.' + index,
                                additionalInfo: 'Here is some additional information.' + index,
                                lng: item.lng,
                                lat: item.lat,
                                height: item.altitude,
                                distance: distance, //
                                time: item.gpsTime,
                                speed: 0,
                                ranking: rank + 1,
                                deviceVol: item.deviceVol,  // 电量
                                // 你可以添加更多自定义属性
                            },

                        });
                    }

                    // 更新上一个点的位置和时间
                    previousPosition = position;
                    previousTime = currentTime;
                    // 方向
                    // let direction;


                });

                // console.log("遍历每个线的坐标点数组",val[0]);
                // 在viewer中添加一个新的线实体
                window.viewer2D.entities.add({
                    id: "polyline_" + val[0].deviceImei,
                    polyline: {
                        positions: positions,

                        // 线宽
                        width: 5,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                        // 路线颜色
                        material: Cesium.Color.fromCssColorString(myColor),
                        // 是否贴地
                        clampToGround: true,
                    },
                });

                console.log("markers", markers);
                // 在viewer中添加标记点实体
                markers.forEach(marker => {
                    window.viewer2D.entities.add(marker);
                });

            });
        },
        getRandomColor() {
            var str = '#';
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

            let color;
            do {
                color = '#';
                for (var i = 0; i < 6; i++) {
                    var num = parseInt(Math.random() * 16);
                    color += arr[num];
                }
            } while (this.generatedColors.has(color)); // 检查颜色是否已存在

            // 添加新颜色到集合中
            this.generatedColors.add(color);

            return color;
        },

    }
}
</script>

<style lang="less" scoped></style>